import { DateTime } from "luxon";
import { IBingoSquareEntry } from "../interfaces/IBingoSquareEntry";
import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";

interface MainBoardSquareEntryFieldsProps {
  readOnly: boolean;
  squarePersonInfo: IBingoSquareEntry;
  setSquarePersonInfo: React.Dispatch<React.SetStateAction<IBingoSquareEntry>>;
}

export const MainBoardSquareEntryFields = (
  props: MainBoardSquareEntryFieldsProps
) => {
  const { readOnly, squarePersonInfo, setSquarePersonInfo } = props;

  const INPUT_CLASSNAME = `input border-solid border-chocolate border-b text-xl my-2 p-2 bg-transparent text-center ${
    readOnly && "unselectable"
  }`;

  return (
    <div className="flex flex-col font-hoss font-light uppercase">
      <input
        disabled={readOnly}
        readOnly={readOnly}
        className={`${INPUT_CLASSNAME}`}
        name="date"
        id="date"
        type="date"
        onClick={readOnly ? (e) => e.preventDefault() : undefined}
        placeholder="WHEN WAS THE FIRST DATE?"
        value={squarePersonInfo.date.toFormat("yyyy-MM-dd")}
        onChange={(e) => {
          if (!readOnly) {
            setSquarePersonInfo({
              ...squarePersonInfo,
              date: DateTime.fromISO(e.target.value),
            });
          }
        }}
      />
      <input
        readOnly={readOnly}
        className={`${INPUT_CLASSNAME} uppercase`}
        type="text"
        placeholder="WHAT WAS THEIR NAME?"
        onChange={(e) => {
          setSquarePersonInfo({
            ...squarePersonInfo,
            dated: true,
            name: e.target.value,
          });
        }}
        value={squarePersonInfo.name}
      ></input>
      <TextareaAutosize
        readOnly={readOnly}
        maxRows={3}
        className={`text-xl my-2 p-4 uppercase bg-transparent text-center border-chocolate border rounded-[6px] min-h-[100px] mt-4 resize-none focus:outline-0`}
        placeholder="GIVE US THE DEETS!"
        onChange={(e) => {
          setSquarePersonInfo({
            ...squarePersonInfo,
            details: e.target.value,
          });
        }}
        value={squarePersonInfo.details}
      ></TextareaAutosize>
    </div>
  );
};
