import { MenuButton } from "../components/buttons/MenuButton";
import { SecondaryLogo } from "../logos/SecondaryLogo";
import { BoardThumbnail } from "../boardThumbnail/BoardThumbnail";
import { useEffect, useState } from "react";
import { CreateBoardThumbnail } from "../boardThumbnail/CreateBoardThumbnail";
import { MenuPage } from "../menu/MenuPage";
import { useAuth } from "../contexts/AuthContext";
import { IBingoBoard } from "../interfaces/IBingoBoard";
import { LoadingDeb } from "../loading/loadingDeb";
import { HomePageViewStateToggle } from "./HomePageViewStateToggle";
import { SpectateBoardEntry } from "../spectator/SpectateBoardEntry";

export enum HomePageViewState {
  PLAY,
  WATCH,
}

export const YourBoards = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [boards, setBoards] = useState<IBingoBoard[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [viewState, setViewState] = useState<HomePageViewState>(
    HomePageViewState.PLAY
  );
  const authInfo = useAuth();

  useEffect(() => {
    setLoading(true);
    const userId = authInfo.currentFirebaseUser?.uid;
    if (userId) {
      setUserName(authInfo.currentUserInfo?.displayName ?? "");
      fetch(`/bingo_board/user/${encodeURIComponent(userId)}`)
        .then((res) => res.json())
        .then((boards) => {
          setBoards(boards);
          setLoading(false);
        });
    }
  }, [
    authInfo.currentFirebaseUser?.uid,
    authInfo.currentUserInfo?.displayName,
  ]);

  return menuOpen ? (
    <MenuPage setMenuOpen={setMenuOpen} />
  ) : (
    <div>
      <MenuButton onClick={() => setMenuOpen(true)} />
      <SecondaryLogo />
      <div className={`flex flex-col justify-center relative top-[100px]`}>
        <h1 className="text-5xl m-4 self-center">
          {`HI ${userName}!`.toUpperCase()}
        </h1>
        <HomePageViewStateToggle
          viewState={viewState}
          setViewState={setViewState}
          selectedClassName="bg-cream"
          unselectedClassName="bg-orange opacity-40"
        />
        {loading ? (
          <div
            className={`flex flex-col items-center justify-center h-[300px]`}
          >
            <LoadingDeb />
          </div>
        ) : viewState == HomePageViewState.PLAY ? (
          <div className="flex flex-col">
            <h1 className="uppercase font-hoss font-light text-center text-2xl mt-4 mx-4">
              {boards.length == 0 ? (
                <p>
                  You don't have any boards yet.{" "}
                  <a className="underline" href="/create-board">
                    Start one
                  </a>!
                </p>
              ) : (
                "Your boards"
              )}
            </h1>
            <div className="px-10 mt-4 items-start overflow-scroll flex flex-row ">
              {boards.map((board: IBingoBoard) => (
                <BoardThumbnail
                  key={`thumbnail_${board.id}`}
                  url={`/board/${board.id}`}
                  title={board.title}
                  size={5}
                  users={board.players}
                />
              ))}
              <CreateBoardThumbnail />
            </div>
          </div>
        ) : (
          <SpectateBoardEntry />
        )}
      </div>
    </div>
  );
};
