import { Dialog, DialogContent } from "@mui/material";

interface StandardDialogProps {
  open: boolean;
  handleExit: () => void;
  title: string;
  content?: React.ReactNode;
  className?: string;
}

export const StandardDialog = (props: StandardDialogProps) => {
  const { open, handleExit, title, content, className } = props;

  const closeButton = (
    <div
      className="cursor-pointer absolute top-0 right-3 font-hoss text-3xl"
      onClick={handleExit}
    >
      x
    </div>
  );

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "12px",
          borderColor: "#4c352a",
          borderWidth: "1px",
          maxWidth: "320px",
        },
      }}
      classes={{
        paper: "dialog-background",
      }}
      open={open}
      onClose={handleExit}
    >
      <DialogContent
        className={`flex flex-col text-chocolate items-center justify-center bg-cream overflow-visible border-none border-chocolate rounded-[11px] ${className}`}
      >
        {closeButton}
        <div className="text-3xl p-3 text-center uppercase">
          {title}
        </div>
        {content}
      </DialogContent>
    </Dialog>
  );
};
