import { Drawer} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { IWildBallRule } from "../../../interfaces/IWildBallRule";
import { WildBallsEditTable } from "./WildBallsEditTable";
import { IWildBallEntry } from "../../../interfaces/IWildBallEntry";
import { useAuth } from "../../../contexts/AuthContext";
import { getBlankAvatarPathForId } from "../../../utils/helpers";
import { IUserShortInfo } from "../../../interfaces/IUserShortInfo";
import { useNavigate } from "react-router-dom";
import { WildBallsViewTable } from "./WildBallsViewTable";
import { WildBallsRedeemView } from "./WildBallsRedeemView";
import { RedeemWildBallsBoard } from "./RedeemWildBallsBoard";
import { IBingoSquare } from "../../../interfaces/IBingoSquare";

interface WildBallsBottomSheetProps {
  open: boolean;
  handleExit: () => void;
  boardId: string;
  user: IUserShortInfo;
  board: IBingoSquare[][];
}

export enum WildBallsBottomSheetMode {
  EDIT,
  VIEW,
  REDEEM,
  FINALIZE_REDEMPTION,
}

export const WildBallsBottomSheet = (props: WildBallsBottomSheetProps) => {
  const { open, handleExit, boardId, user, board } = props;
  const [rules, setRules] = useState<IWildBallRule[]>([]);
  const [entries, setEntries] = useState<IWildBallEntry[]>([]);
  const [rulesAndEntries, setRulesAndEntries] = useState<
    {
      id: string;
      text: string;
      quantity: number;
      pointsValue: number;
    }[]
  >([]);
  const [mode, setMode] = useState<WildBallsBottomSheetMode>(
    WildBallsBottomSheetMode.VIEW
  );
  const [redeemedPoints, setRedeemedPoints] = useState<number>(0);
  const authInfo = useAuth();
  const navigate = useNavigate();

  const canViewerEdit = authInfo.currentUserInfo?.id == user.id;

  const redeemablePoints =
    rulesAndEntries.reduce(
      (acc, curr) => acc + curr.pointsValue * curr.quantity,
      0
    ) - redeemedPoints;

  const onRedeemWildBall = (square: IBingoSquare) => {
    Promise.all([
      fetch(`/square_entries/wildBall/${boardId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          row: square.row,
          col: square.col,
          userId: user.id,
        }),
      }),
      fetch(`/wild_balls_entries/setRedeemedPoints/${boardId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          pointsRedeemed: redeemedPoints + 10,
          userId: user.id,
        }),
      }),
    ]).then((_res) => {
      onExit();
      navigate(0);
    });
  };

  const onExit = () => {
    setMode(WildBallsBottomSheetMode.VIEW);
    handleExit();
  };

  useEffect(() => {
    setRulesAndEntries(
      rules.map((rule: IWildBallRule) => {
        const ruleId = rule.id as string;
        const matchingEntry = entries.find((entry) => entry.ruleId == ruleId);
        let quantity = 0;
        if (matchingEntry) {
          quantity = matchingEntry.quantity;
        }
        return { ...rule, quantity: quantity };
      })
    );
  }, [rules, entries]);

  useEffect(() => {
    if (boardId) {
      Promise.all([
        fetch(`/wild_balls_rules/${encodeURIComponent(boardId)}`)
          .then((res) => res.json())
          .then((data) => {
            setRules(data);
          }),
        fetch(`/wild_balls_entries/${encodeURIComponent(boardId)}/${user.id}`)
          .then((res) => res.json())
          .then((data) => {
            setEntries(data);
          }),
        fetch(
          `/wild_balls_entries/getRedeemedPoints/${encodeURIComponent(
            boardId
          )}/${user.id}`
        )
          .then((res) => res.json())
          .then((data) => {
            setRedeemedPoints(data);
          }),
      ]);
    }
  }, [boardId, user]);

  const updateRuleEntryForUser = async (ruleId: string, quantity: number) => {
    let entriesCopy = [...entries];
    const matchingEntryIndex = entries.findIndex(
      (entry) => entry.ruleId == ruleId
    );
    if (matchingEntryIndex != -1) {
      entriesCopy[matchingEntryIndex].quantity = quantity;
    } else {
      entriesCopy.push({ quantity: quantity, ruleId: ruleId });
    }
    setEntries(entriesCopy);

    await fetch(`/wild_balls_entries/${boardId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: user.id,
        ruleId: ruleId,
        quantity: quantity,
      }),
    });
    // .then((res) => res.json())
    // .then((data) => {
    // // TODO: error handling
    // });
  };

  const onSave = async (editedRules: IWildBallRule[]) => {
    await fetch(`/wild_balls_rules/update/${boardId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        rules: editedRules,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setMode(WildBallsBottomSheetMode.VIEW);
        // TODO: error handling
      });
  };

  const getComponentForMode = (mode: WildBallsBottomSheetMode) => {
    switch (mode) {
      case WildBallsBottomSheetMode.VIEW:
        return (
          <WildBallsViewTable
            rulesAndEntries={rulesAndEntries}
            setMode={setMode}
            updateRuleEntryForUser={updateRuleEntryForUser}
            canViewerEdit={canViewerEdit}
            redeemablePoints={redeemablePoints}
          />
        );
      case WildBallsBottomSheetMode.EDIT:
        return (
          <WildBallsEditTable
            rules={rules}
            onSave={onSave}
            setRules={setRules}
            boardId={boardId}
          />
        );
      case WildBallsBottomSheetMode.REDEEM:
        return (
          <WildBallsRedeemView
            redeemablePoints={redeemablePoints}
            user={user}
            onExit={() => setMode(WildBallsBottomSheetMode.VIEW)}
            onRedeem={() =>
              setMode(WildBallsBottomSheetMode.FINALIZE_REDEMPTION)
            }
          />
        );
      case WildBallsBottomSheetMode.FINALIZE_REDEMPTION:
        return (
          <RedeemWildBallsBoard
            onRedeem={onRedeemWildBall}
            board={board}
            user={user}
          />
        );
    }
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onExit}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      classes={{
        paper: "dialog-background",
      }}
    >
      <img
        src={`/assets/avatars/${getBlankAvatarPathForId(user.avatarId)}`}
        className="w-16 opacity-80 absolute -top-8 left-[50%] -translate-x-[50%] z-10"
      ></img>
      <div
        className="cursor-pointer absolute top-0 z-10 right-3 font-hoss text-3xl"
        onClick={onExit}
      >
        x
      </div>
      <div
        className={`max-h-[80vh] overflow-scroll bg-orange items-center flex flex-col p-6 border-chocolate border-t border-x rounded-t-[11px]`}
      >
        <img src="/assets/wb/WB_Title.png" className="w-[280px] mt-6 mb-4" />
        <p className="text-russet text-xl mb-4">+10 POINTS = 1 WILD BALL</p>
        {getComponentForMode(mode)}
      </div>
    </Drawer>
  );
};
