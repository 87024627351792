import { useState } from "react";
import { StandardButton } from "./buttons/StandardButton";
import { IBingoSquare } from "../interfaces/IBingoSquare";
import { useScreenDimensions } from "../hooks/useScreenDimensions";

interface EditBoardViewProps {
  title: string;
  board: IBingoSquare[][];
  onSave: (board: IBingoSquare[][], title: string) => void;
  onBack?: () => void;
}

export const EditBoardView = (props: EditBoardViewProps) => {
  const { title, board, onSave, onBack } = props;
  const [selectedSquareIndex, setSelectedSquareIndex] = useState<number>(0);
  const screenDims = useScreenDimensions();
  const [boardTitle, setBoardTitle] = useState<string>(title);

  return (
    <div className={`flex flex-col justify-center`}>
      <input
        className={`input font-mencken text-4xl input border-b border-solid text-center my-2 bg-chocolate10`}
        value={boardTitle.toUpperCase()}
        onChange={(e) => {
          setBoardTitle(e.target.value);
        }}
      ></input>

      <p className="text-center font-hoss font-light uppercase text-sm">
        Give your board a cool name
      </p>
      <div className="relative mt-8 flex flex-col">
        <div className="text-2xl font-mencken bg-avocado p-2 absolute left-2/4 -translate-x-2/4 -top-5">
          YOUR SQUARES
        </div>
        <div className="flex border border-solid rounded-xl border-black w-[100%] font-hoss font-light">
          <div className="mx-[24px] mt-[34px] w-[100%] h-[30vh] overflow-scroll">
            <ol>
              {board.map((row: IBingoSquare[], rowIndex: number) =>
                row.map((square: IBingoSquare, colIndex: number) => {
                  const index = colIndex + rowIndex * row.length;
                  return (
                    <li
                      className={`px-2 rounded-[6px] flex flex-row ${
                        index == selectedSquareIndex ? "bg-chocolate10" : ""
                      }`}
                      key={`li_${index}`}
                      onClick={() => {
                        setSelectedSquareIndex(index);
                      }}
                    >
                      <div className="mr-2">{`${index + 1}.`}</div>

                      <div
                        className="input uppercase"
                        id={`content_${rowIndex}.${colIndex}`}
                        contentEditable
                        suppressContentEditableWarning
                        autoFocus
                      >
                        {square.title}
                      </div>
                    </li>
                  );
                })
              )}
            </ol>
          </div>
        </div>
        <p className="text-center font-hoss font-light uppercase mt-2 text-sm">
          Click each line to edit
        </p>
        <div className="flex flex-col">
          <div className="flex flex-row">
            {onBack && (
              <StandardButton
                className="mt-6 mb-3 py-1 text-xl bg-cream text-chocolate px-3 mr-2"
                text="BACK"
                onClick={onBack}
              />
            )}
            <StandardButton
              className="mt-6 mb-3 py-1 grow text-xl bg-teal text-cream"
              text="DONE FOR NOW"
              onClick={() => onSave(board, boardTitle)}
            />
          </div>
          <p className="text-center font-hoss font-light text-base">
            YOU CAN CONTINUE MAKING EDITS AT ANY TIME
          </p>
        </div>
      </div>
    </div>
  );
};
