import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { CloseMenuButton } from "../components/buttons/CloseMenuButton";
import { MenuOptionButton } from "../components/buttons/MenuOptionButton";
import { LogOutDialog } from "./LogOutDialog";
import { HowToPlay } from "./HowToPlay";
import { CustomizeAccountPage } from "../accounts/CustomizeAccountPage";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { HowToPlayCarousel } from "../homepage/HowToPlayCarousel";

export const MenuPage = (props: {
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  enum MenuSubPage {
    OPTIONS,
    HOW_TO_PLAY,
    ACCOUNT,
  }

  const [logoutDialogOpen, setLogoutDialogOpen] = useState<boolean>(false);
  const [subPageOpen, setSubPageOpen] = useState<MenuSubPage>(
    MenuSubPage.OPTIONS
  );
  const authInfo = useAuth();
  const navigate = useNavigate();

  const getComponentForSubPage = (subPage: MenuSubPage) => {
    switch (subPage) {
      case MenuSubPage.OPTIONS:
        return (
          <div className="flex flex-col items-center justify-center w-[100%] full-screen-height">
            <img
              className="w-[30vh] p-6"
              src="/assets/DB_Logo_Stacked_PinkO_Slant.png"
            />
            <div className="font-hoss font-light">
              DON'T HATE THE PLAYER, LOVE THE GAME
            </div>
            <div className={`my-6 w-[80%] h-[1px] bg-black`}></div>
            <MenuOptionButton
              title={"HOW TO PLAY"}
              onClick={() => setSubPageOpen(MenuSubPage.HOW_TO_PLAY)}
            />
            {!authInfo.currentUserInfo && (
              <MenuOptionButton
                title={"SIGN IN/SIGN UP"}
                onClick={() => navigate("/login")}
              />
            )}
            {authInfo.currentUserInfo && (
              <Fragment>
                <MenuOptionButton
                  title={"YOUR ACCOUNT"}
                  onClick={() => setSubPageOpen(MenuSubPage.ACCOUNT)}
                />
                <MenuOptionButton
                  title={"LOG OUT"}
                  onClick={() => setLogoutDialogOpen(true)}
                />
              </Fragment>
            )}
            <MenuOptionButton
              title={"GOT Q'S? SLIDE IN"}
              className="text-bubblegum"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/datingbingo/",
                  "_blank",
                  "noreferrer"
                )
              }
            />
            <LogOutDialog
              open={logoutDialogOpen}
              handleExit={() => setLogoutDialogOpen(false)}
            />
          </div>
        );
      case MenuSubPage.HOW_TO_PLAY:
        return <div className="mt-16"><HowToPlayCarousel /></div>;
      case MenuSubPage.ACCOUNT:
        return (
          <CustomizeAccountPage
            isNewUser={false}
            onClose={() => props.setMenuOpen(false)}
          />
        );
    }
  };

  return (
    <div className="flex flex-col items-center m-4 text-center">
      <CloseMenuButton onClick={() => props.setMenuOpen(false)} />
      {getComponentForSubPage(subPageOpen)}
    </div>
  );
};
