import { useState } from "react";
import { InviteFriendsBottomSheet } from "../bottomsheets/InviteFriendsBottomSheet";
import { IBingoBoard } from "../../interfaces/IBingoBoard";
import { WildBallsBottomSheet } from "../bottomsheets/wildballs/WildBallsBottomSheet";
import { IUserShortInfo } from "../../interfaces/IUserShortInfo";

interface MenuBarProps {
  board: IBingoBoard;
  inEditMode: boolean;
  setInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: IUserShortInfo | undefined;
}

export const MenuBar = (props: MenuBarProps) => {
  const { board, inEditMode, setInEditMode, selectedUser } = props;
  const [menuItemOpen, setMenuItemOpen] = useState<number>();
  const [inviteFriendsBottomSheetOpen, setInviteFriendsBottomSheetOpen] =
    useState<boolean>(false);
  const [wildBallsBottomSheetOpen, setWildBallsBottomSheetOpen] =
    useState<boolean>(false);

  const menuItems = [
    {
      title: "WILD BALLS",
      icon: "/wb/WB_Icon_Main.png",
      onClick: () => setWildBallsBottomSheetOpen(true),
      bottomSheet: selectedUser && board.squares && (
        <WildBallsBottomSheet
          key="wild_balls_bottomsheet"
          open={wildBallsBottomSheetOpen}
          handleExit={() => setWildBallsBottomSheetOpen(false)}
          boardId={board.id}
          user={selectedUser}
          board={board.squares}
        />
      ),
      disabled: !selectedUser || !board.squares,
    },
    {
      title: "INVITE FRIENDS",
      icon: "deb.png",
      onClick: () => setInviteFriendsBottomSheetOpen(true),
      bottomSheet: (
        <InviteFriendsBottomSheet
          key={`invite_bottomsheet`}
          board={board}
          handleExit={() => setInviteFriendsBottomSheetOpen(false)}
          open={inviteFriendsBottomSheetOpen}
        />
      ),
      disabled: false,
    },
    {
      title: "EDIT BOARD",
      icon: "/avatars/BBs_Cream.png",
      onClick: () => setInEditMode(true),
      bottomSheet: null,
      disabled: false,
    },
  ];

  const items = menuItems.filter((item) => !item.disabled);

  return (
    <div className="fixed bottom-0 w-[100vw] h-[70px] bg-chocolate flex flex-row justify-around items-center">
      {items
        .filter((item) => !item.disabled)
        .map((item, i) => [
          <div
            className={`flex flex-col items-center cursor-pointer ${
              item.disabled && "opacity-40"
            }`}
            onClick={!item.disabled ? item.onClick : undefined}
            key={`menu_item_${i}`}
          >
            <img src={`/assets/${item.icon}`} className="w-[28px] mb-1" />
            <div className="text-sm font-hoss font-light text-cream text-center flex flex-row">
              {item.title}
            </div>
          </div>,
          i != items.length - 1 ? (
            <div
              key={`menu_divider_${i}`}
              className="bg-cream w-[1px] h-[70%]"
            ></div>
          ) : null,
          item.bottomSheet,
        ])}
    </div>
  );
};
