export enum Avatar {
  PINK,
  RED,
  PURPLE,
  ORANGE,
  BLUE,
  GREEN,
}

export const Avatars = [
  Avatar.PINK,
  Avatar.RED,
  Avatar.PURPLE,
  Avatar.ORANGE,
  Avatar.BLUE,
  Avatar.GREEN,
];

export const castStringToAvatar = (id: string) => {
  switch (id) {
    case "0":
      return Avatar.PINK;
    case "1":
      return Avatar.RED;
    case "2":
      return Avatar.PURPLE;
    case "3":
      return Avatar.ORANGE;
    case "4":
      return Avatar.BLUE;
    case "5":
    default:
      return Avatar.GREEN;
  }
};

export const getAvatarPathForId = (id?: Avatar) => {
  switch (id) {
    case Avatar.PINK:
      return "BBs_Pink.png";
    case Avatar.RED:
      return "BBs_Red.png";
    case Avatar.PURPLE:
      return "BBs_Purple.png";
    case Avatar.ORANGE:
      return "BBs_Orange.png";
    case Avatar.BLUE:
      return "BBs_Blue.png";
    case Avatar.GREEN:
    default:
      return "BBs_Green.png";
  }
};

export const getBlankAvatarPathForId = (id?: Avatar) => {
  switch (id) {
    case Avatar.PINK:
      return "BBs_Pink_Blank.png";
    case Avatar.RED:
      return "BBs_Red_Blank.png";
    case Avatar.PURPLE:
      return "BBs_Purple_Blank.png";
    case Avatar.ORANGE:
      return "BBs_Orange_Blank.png";
    case Avatar.BLUE:
      return "BBs_Blue_Blank.png";
    case Avatar.GREEN:
    default:
      return "BBs_Green_Blank.png";
  }
};

export const getWildBallPathForAvatarId = (id?: Avatar) => {
  switch (id) {
    case Avatar.PINK:
      return "/wb/WB_Icon_Pink.png";
    case Avatar.RED:
      return "/wb/WB_Icon_Red.png";
    case Avatar.PURPLE:
      return "/wb/WB_Icon_Purple.png";
    case Avatar.ORANGE:
      return "/wb/WB_Icon_Orange.png";
    case Avatar.BLUE:
      return "/wb/WB_Icon_Blue.png";
    case Avatar.GREEN:
    default:
      return "/wb/WB_Icon_Green.png";
  }
};

export const shuffleArray = (array: any[]) => {
  array.sort((a, b) => 0.5 - Math.random());
  return array;
};

export function shuffledPositionsForBoard(boardSize: number) {
  let positions = [
    { left: `left-[0%]`, top: `top-[30%]` },
    { left: `left-[20%]`, top: `-top-[0%]` },
    { left: `left-[32%]`, top: `top-[44%]` },
    { left: `left-[40%]`, top: `top-[5%]` },
    { left: `-left-[8%]`, top: `top-[20%]` },
    { left: `-left-[5%]`, top: `-top-[0%]` },
    { left: `left-[14%]`, top: `top-[32%]` },
  ];
  // let positions: {left: string, top: string}[] = [];
  // for (let i = 0; i < 10; i++) {
  //   const left = Math.random() * 45 - 5;
  //   const top = Math.random() * 45 - 5;
  //   positions.push({left: `${left}%`, top: `${top}%`});
  // }

  let board = new Array(boardSize);
  for (let i = 0; i < board.length; i++) {
    board[i] = new Array(boardSize);
    for (let j = 0; j < board.length; j++) {
      board[i][j] = shuffleArray([...positions]);
    }
  }
  return board;
}

export const createEmptyBoard = (board_size: number, defaultVal?: any) => {
  let board = new Array(board_size);
  for (let i = 0; i < board.length; i++) {
    board[i] = new Array(board_size).fill(defaultVal ?? null);
  }
  return board;
};
