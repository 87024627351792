import { useScreenDimensions } from "../hooks/useScreenDimensions";

export const INSTRUCTIONS = [
  {
    title: "DESIGN A BOARD",
    rule: "Create a board and customize it. We'll generate some squares for you, but change them however you'd like. After all, one person's red flag is another person's green flag!",
    gif_url: "/assets/howtoplay/DB_Tutorial1.gif",
  },
  {
    title: "INVITE PLAYERS",
    rule: "Invite a few friends to play with you (or just watch ;))",
    gif_url: "/assets/howtoplay/DB_Tutorial2.gif",
  },
  {
    title: "LOG DATES",
    rule: "A square can be counted as complete when you go on a first date with someone who meets its description. Don’t count past dates!",
    gif_url: "/assets/howtoplay/DB_Tutorial3.gif",
  },
  {
    title: "GET WILD BALLS",
    rule: "For every game, you can set rules to award (or detract) points for certain actions taken on dates. You can trade in every 10 points for 1 Wild Ball, which can be used to complete any square on your board.",
    gif_url: "/assets/howtoplay/DB_Tutorial4.gif",
  },
  {
    title: "COMPETE",
    rule: "The first player to complete every square in a row, column, or diagonal on the board wins. Make sure to set some high stakes!",
    gif_url: "/assets/howtoplay/DB_Tutorial5.gif",
  },
];

export const HowToPlay = () => {
  const screenDims = useScreenDimensions();
  const smallScreen = screenDims.width < 640;

  return (
    <div className="flex flex-col items-center mt-20 m-8">
      <h1 className="text-4xl font-mencken underline">HOW TO PLAY</h1>
      <div className="flex flex-col wrap">
      {INSTRUCTIONS.map((section, i) => (
        <div
          className="flex flex-col items-center text-center"
          key={`rule_${i}`}
        >
          <h1 className="text-3xl font-mencken mb-4 mt-6">
            {i + 1}. {section.title}
          </h1>
          <div
            className={`flex flex-col items-center`}
          >
            {section.gif_url && (
              <img
                src={section.gif_url}
                className="border border-cream border-[4px] rounded-[6px] m-2 w-[calc(min(300px,80%))]"
              />
            )}
            <p className="font-hoss uppercase text-start text-lg max-w-[calc(min(300px,80%))]">
              {section.rule}
            </p>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};
