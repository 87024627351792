import { IBingoSquare } from "../../interfaces/IBingoSquare";
import {
  IBingoSquareEntry,
  defaultBingoSquareEntry,
} from "../../interfaces/IBingoSquareEntry";
import { IMainBoardPlayersTrayOption } from "../../interfaces/IMainBoardPlayersTrayOption";
import { useState, useEffect } from "react";
import { StandardButton } from "../../components/buttons/StandardButton";
import { useAuth } from "../../contexts/AuthContext";
import { MainBoardSquareEntryFields } from "../MainBoardSquareEntryFields";
import { getBlankAvatarPathForId } from "../../utils/helpers";
import { StandardBottomSheet } from "../../components/StandardBottomSheet";

interface MainBoardSquareBottomSheetProps {
  open: boolean;
  square: IBingoSquare;
  handleExit: () => void;
  onSaveSquareEntry: (
    userId: string,
    square: IBingoSquare,
    newEntry: IBingoSquareEntry
  ) => void;
  onDeleteSquareEntry: (userId: string, square: IBingoSquare) => void;
  selectedTrayOption: IMainBoardPlayersTrayOption;
}

export const MainBoardSquareBottomSheet = (
  props: MainBoardSquareBottomSheetProps
) => {
  const {
    open,
    square,
    handleExit,
    onSaveSquareEntry,
    onDeleteSquareEntry,
    selectedTrayOption,
  } = props;

  const [squarePersonInfo, setSquarePersonInfo] = useState<IBingoSquareEntry>(
    defaultBingoSquareEntry()
  );
  const [canViewerEditSquare, setCanViewerEditSquare] =
    useState<boolean>(false);
  const authInfo = useAuth();

  useEffect(() => {
    setSquarePersonInfo(squareExistingEntry() ?? defaultBingoSquareEntry());
  }, [square, selectedTrayOption]);

  useEffect(() => {
    setCanViewerEditSquare(
      selectedTrayOption.user != null &&
        authInfo.currentUserInfo?.id == selectedTrayOption.user?.id
    );
  }, [authInfo.currentUserInfo?.id, selectedTrayOption]);

  const squareExistingEntry = (): IBingoSquareEntry | null => {
    if (selectedTrayOption.user) {
      const selectedSquareEntry = square?.entries.get(
        selectedTrayOption.user.id
      );
      return selectedSquareEntry ?? null;
    }
    return null;
  };

  const getAvatarImage = () => {
    if (selectedTrayOption.user) {
      return (
        "avatars/" + getBlankAvatarPathForId(selectedTrayOption.user.avatarId)
      );
    }
    return "deb.png";
  };

  const content = () => {
    const editable = canViewerEditSquare;
    if (!editable && !squarePersonInfo.dated) {
      return null;
    }
    const readOnly = !editable;

    let buttons = [];
    if (editable) {
      buttons.push(
        <StandardButton
          key="dialog-save-button"
          disabled={squarePersonInfo.name.length == 0}
          text="SAVE"
          className="text-xl bg-teal py-1 grow text-cream"
          onClick={() => {
            if (selectedTrayOption.user) {
              onSaveSquareEntry(
                selectedTrayOption.user?.id,
                square,
                squarePersonInfo
              );
            }
          }}
        />,
        squareExistingEntry() ? (
          <StandardButton
            key="dialog-delete-button"
            text="DELETE"
            className="text-xl bg-firetruck text-cream ml-2 py-1 px-2"
            onClick={() => {
              if (selectedTrayOption.user) {
                onDeleteSquareEntry(selectedTrayOption.user?.id, square);
              }
            }}
          />
        ) : null
      );
    }

    return (
      <div className="flex flex-col mx-2">
        <MainBoardSquareEntryFields
          squarePersonInfo={squarePersonInfo}
          setSquarePersonInfo={setSquarePersonInfo}
          readOnly={readOnly}
        />
        <div className="flex flex-row items-center justify-end mt-2">
          {buttons}
        </div>
      </div>
    );
  };

  return (
    <StandardBottomSheet
      title={square.title}
      open={open}
      handleExit={handleExit}
      content={
        selectedTrayOption.visibilityControl != null ||
        squarePersonInfo.wildBall
          ? null
          : content()
      }
      imgUrl={getAvatarImage()}
    />
  );
};
