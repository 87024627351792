import { HomePageViewState } from "./YourBoards";

interface HomePageViewStateProps {
  viewState: HomePageViewState;
  setViewState: React.Dispatch<React.SetStateAction<HomePageViewState>>;
  selectedClassName: string;
  unselectedClassName: string;
}

export const HomePageViewStateToggle = (props: HomePageViewStateProps) => {
  const { viewState, setViewState, selectedClassName, unselectedClassName } = props;
  const commonButtonClassName = "grow py-0 px-3";
  const borderClassName = "border border-solid border-chocolate";
  return (
    <div className="flex flex-row justify-center text-xl cursor-pointer text-chocolate m-4">
      <div
        className={`${borderClassName} rounded-l-[6px]`}
        onClick={() => setViewState(HomePageViewState.PLAY)}
      >
        <div
          className={`rounded-l-[6px] px-4 text-center ${commonButtonClassName} ${
            viewState == HomePageViewState.PLAY
              ? selectedClassName
              : unselectedClassName
          }`}
        >
          PLAY
        </div>
      </div>
      <div
        className={`${borderClassName} border-l-0 rounded-r-[6px]`}
        onClick={() => setViewState(HomePageViewState.WATCH)}
      >
        <div
          className={`rounded-r-[6px] ${commonButtonClassName} ${
            viewState == HomePageViewState.WATCH
              ? selectedClassName
              : unselectedClassName
          }`}
        >
          WATCH
        </div>
      </div>
    </div>
  );
};
