import { getBorderAttributeForCell } from "../utils/boardHelpers";
import { useScreenDimensions } from "../hooks/useScreenDimensions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { IBingoSquare } from "../interfaces/IBingoSquare";
import { IMainBoardPlayersTrayOption } from "../interfaces/IMainBoardPlayersTrayOption";
import { IBingoSquareEntry } from "../interfaces/IBingoSquareEntry";
import { ReactNode, useEffect } from "react";
import {
  getBlankAvatarPathForId,
  getWildBallPathForAvatarId,
  shuffledPositionsForBoard,
} from "../utils/helpers";
import { IUserShortInfo } from "../interfaces/IUserShortInfo";
import { MainBoardPlayersTrayVisibilityControl } from "../board/MainBoardPlayersTray";

interface BaseBoardViewProps {
  board: IBingoSquare[][];
  selectedTrayOption?: IMainBoardPlayersTrayOption;
  onClickSquare?: (square: IBingoSquare) => void;
  squareClassName?: string;
  players?: IUserShortInfo[];
  boardSquarePositions?: { left: string; top: string }[][][];
  selectedWildBallSquare?: IBingoSquare;
}

export const BaseBoardView = (props: BaseBoardViewProps) => {
  const {
    board,
    selectedTrayOption,
    onClickSquare,
    squareClassName,
    players,
    boardSquarePositions,
    selectedWildBallSquare,
  } = props;
  const size: number = board.length;

  const screenDims = useScreenDimensions();

  useEffect(() => {
    const boardWidth = Math.min(screenDims.width, screenDims.height - 200) - 80;
    const cellSize = boardWidth / size;
    document.documentElement.style.setProperty("--cell-size", `${cellSize}px`);
    document.documentElement.style.setProperty(
      "--board-width",
      `${boardWidth}px`
    );
  }, [screenDims]);

  // Only used for Wild Ball Placement screen
  const getWildBallToPlaceForSquare = (square: IBingoSquare) => {
    // there should be exactly one player
    if (!players || players.length != 1) {
      return null;
    }
    const styleClasses =
      "w-[80%] left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4";
    if (square == selectedWildBallSquare) {
      return (
        <div className={`z-10 absolute opacity-80 ${styleClasses}`}>
          {
            <img
              src={`/assets/${getWildBallPathForAvatarId(players[0].avatarId)}`}
            ></img>
          }
        </div>
      );
    }
    return null;
  };

  const getTokensForSquare = (
    square: IBingoSquare,
    selectedTrayOption: IMainBoardPlayersTrayOption
  ) => {
    if (
      selectedTrayOption.visibilityControl != null &&
      selectedTrayOption.visibilityControl ==
        MainBoardPlayersTrayVisibilityControl.NONE
    ) {
      return null;
    }
    let tokens: ReactNode[] = [];
    square.entries.forEach((entry: IBingoSquareEntry, uid: string) => {
      if (selectedTrayOption.user && selectedTrayOption.user.id != uid) {
        return;
      }

      const player = players?.find((player) => player.id == uid);
      let imgSrc = `/assets/avatars/${getBlankAvatarPathForId(
        player?.avatarId
      )}`;
      if (entry.wildBall) {
        imgSrc = `/assets/${getWildBallPathForAvatarId(player?.avatarId)}`;
      }

      let tokenStyleClasses = "";
      if (!selectedTrayOption.user) {
        if (!boardSquarePositions) {
          return null;
        }
        const { left, top } =
          boardSquarePositions[square.row][square.col][tokens.length];
        tokenStyleClasses = `absolute w-[58%] ${left} ${top}`;
      }
      tokens.push(
        <img
          key={`bingo_marker_${square.row}.${square.col}.${uid}`}
          src={imgSrc}
          className={`${tokenStyleClasses}`}
        ></img>
      );
    });
    let styleClasses = "";
    if (selectedTrayOption.user) {
      styleClasses =
        "w-[80%] left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4";
    } else {
      styleClasses = "w-[100%] h-[100%]";
    }
    return (
      <div className={`z-10 absolute opacity-80 ${styleClasses}`}>{tokens}</div>
    );
  };

  return (
    <TransformWrapper>
      <TransformComponent>
        <div>
          {board.map((row, i) => {
            return (
              <div className="flex" key={`main_board_row_${i}`}>
                {row.map((cell, j) => {
                  const borderStyle = `${getBorderAttributeForCell(
                    size,
                    i,
                    j
                  )} border-[0.5px] border-solid border-chocolate`;
                  return (
                    <div key={`main_board_cell_${i}.${j}`} className="relative">
                      <div
                        className={`flex flex-col py-3 px-1 justify-center ${borderStyle} bingo-board-cell ${squareClassName} bg-cream`}
                        onClick={
                          onClickSquare ? () => onClickSquare(cell) : undefined
                        }
                      >
                        {selectedWildBallSquare &&
                          getWildBallToPlaceForSquare(cell)}
                        {getTokensForSquare(
                          cell,
                          selectedTrayOption ?? {
                            user: null,
                            visibilityControl:
                              MainBoardPlayersTrayVisibilityControl.ALL,
                          }
                        )}
                        <div className="bg-transparent relative align-middle text-center">
                          {cell.title.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
};
