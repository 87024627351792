import { TextareaAutosize } from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { StandardButton } from "../../../components/buttons/StandardButton";
import { WildBallsBottomSheetMode } from "./WildBallsBottomSheet";

interface WildBallViewTableProps {
  rulesAndEntries: {
    id: string;
    text: string;
    quantity: number;
    pointsValue: number;
  }[];
  setMode: React.Dispatch<React.SetStateAction<WildBallsBottomSheetMode>>;
  updateRuleEntryForUser: (ruleId: string, quantity: number) => Promise<void>;
  canViewerEdit: boolean;
  redeemablePoints: number;
}

const WILD_BALL_POINTS_QTY = 10;

export const WildBallsViewTable = (props: WildBallViewTableProps) => {
  const {
    rulesAndEntries,
    setMode,
    updateRuleEntryForUser,
    canViewerEdit,
    redeemablePoints,
  } = props;
  const cellClassName = "p-2 w-[100%] items-center text-center overflow-wrap";

  const canRedeem = redeemablePoints >= WILD_BALL_POINTS_QTY;

  const redeemWildBall = () => {
    if (!canRedeem) {
      return null;
    }
    setMode(WildBallsBottomSheetMode.REDEEM);
  };

  return (
    <div className="flex flex-col w-[100%]">
      <table className="font-hoss font-light text-cream text-lg uppercase w-[100%] max-h-[40vh] overflow-scroll block">
        <thead>
          <tr className="rounded-t-[6px] bg-russet font-mencken text-2xl sticky top-0 ">
            <td className={`rounded-tl-[6px] ${cellClassName}`}>RULE</td>
            <td className={`${cellClassName}`}>POINTS</td>
            <td className={`rounded-tr-[6px] ${cellClassName}`}>QTY</td>
          </tr>
        </thead>
        {rulesAndEntries.length == 0 && (
          <div className="border-russet border w-[100%] p-4 text-center text-xl uppercase">
            No rules yet!
          </div>
        )}
        <tbody className="w-[100%] text-base">
          {rulesAndEntries.map((rule, i) => (
            <tr className="border-russet border" key={`row_` + i}>
              <td
                className={`${cellClassName} text-start min-w-[40vw] max-w-[40vw] `}
              >
                <TextareaAutosize
                  readOnly
                  id={`ruletext_${i}`}
                  className={`p-3 input bg-transparent uppercase text-cream text-start w-[100%] text-lg`}
                  value={rule.text}
                ></TextareaAutosize>
              </td>
              <td className={`${cellClassName} text-russet text-2xl`}>
                {rule.pointsValue > 0 && "+"}
                {rule.pointsValue}
              </td>
              <td className={`${cellClassName}`}>
                <div className="flex flex-row items-center">
                  {canViewerEdit && (
                    <span
                      onClick={() =>
                        rule.id
                          ? updateRuleEntryForUser(rule.id, rule.quantity - 1)
                          : null
                      }
                      className="unselectable cursor-pointer m-2 border border-russet text-russet rounded-[50%] w-[22px] h-[22px] flex text-center items-center justify-center"
                    >
                      -
                    </span>
                  )}
                  <span className="text-2xl">{rule.quantity}</span>
                  {canViewerEdit && (
                    <span
                      onClick={() =>
                        rule.id
                          ? updateRuleEntryForUser(rule.id, rule.quantity + 1)
                          : null
                      }
                      className="unselectable cursor-pointer m-2 border border-russet text-russet rounded-[50%] w-[22px] h-[22px] flex text-center items-center justify-center"
                    >
                      +
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="rounded-b-[6px] bg-russet font-mencken text-2xl w-[100%] sticky bottom-0">
            <td className={`rounded-bl-[6px] ${cellClassName}`}>
              REDEEMABLE POINTS
            </td>
            <td className={`${cellClassName}`}></td>
            <td className={`rounded-br-[6px] ${cellClassName}`}>
              {redeemablePoints > 0 && "+"}
              {redeemablePoints}
            </td>
          </tr>
        </tfoot>
      </table>
      {canViewerEdit && (
        <div className="flex flex-row w-[100%] mt-4">
          <StandardButton
            text="EDIT RULES"
            className="my-2 mr-2 py-1 bg-cream text-russet text-xl px-4"
            onClick={() => setMode(WildBallsBottomSheetMode.EDIT)}
          />
          <StandardButton
            disabled={!canRedeem}
            text="REDEEM"
            className="my-2 py-1 grow bg-canteloupe text-russet text-xl px-4"
            onClick={redeemWildBall}
          />
        </div>
      )}
    </div>
  );
};
