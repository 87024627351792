interface ButtonProps {
  text?: string;
  className?: string;
  imageUrl?: string;
  onClick: () => void;
  disabled?: boolean;
}

export const StandardButton = (props: ButtonProps) => {
  return (
    <button
      onClick={props.disabled ? undefined : props.onClick}
      className={`font-mencken text-4xl 
        border border-solid border-black rounded-[6px]
          flex flex-row 
        justify-center items-center ${props.className}
        ${
          props.disabled
            ? "opacity-40 cursor-default"
            : "cursor-pointer"
            //opacity-60
        }`}
    >
      {props.text}
      {props.imageUrl ? (
        <img
          className={`w-8 ${props.text ? "ml-2" : ""}`}
          src={props.imageUrl}
        />
      ) : null}
    </button>
  );
};
