import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { IBingoSquare } from "../interfaces/IBingoSquare";
import { useAuth } from "../contexts/AuthContext";
import { LoadingDeb } from "../loading/loadingDeb";
import { processBoardFromServer } from "../utils/serverHelpers";
import {
  BoardTheme,
  CreateBoardStage,
  getIndexForBoardTheme,
} from "./CreateBoardPage";
import { EditBoardView } from "../components/EditBoardView";
import { ChooseSquaresViewPage } from "./ChooseSquaresViewPage";

interface ChooseSquaresPageProps {
  boardSize: number;
  selectedTheme: BoardTheme;
  board: IBingoSquare[][] | undefined;
  setBoard: React.Dispatch<React.SetStateAction<IBingoSquare[][] | undefined>>;
  boardStage: CreateBoardStage;
  setBoardStage: React.Dispatch<React.SetStateAction<CreateBoardStage>>;
  currentBoardTheme: BoardTheme;
  setCurrentBoardTheme: React.Dispatch<React.SetStateAction<BoardTheme>>;
}

export const ChooseSquaresPage = (props: ChooseSquaresPageProps) => {
  const {
    boardSize,
    selectedTheme,
    board,
    setBoard,
    boardStage,
    setBoardStage,
    currentBoardTheme,
    setCurrentBoardTheme,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(`${selectedTheme} Board`);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const authInfo = useAuth();
  const navigate = useNavigate();

  let themes = [getIndexForBoardTheme(selectedTheme)];

  const fetchBoardSquares = () => {
    setLoading(true);
    fetch(`/bingo_squares/random/${boardSize}/${JSON.stringify(themes)}`)
      .then((res) => res.json())
      .then((randomBoard) => {
        const processedBoard = processBoardFromServer(randomBoard);
        setBoard(processedBoard);
        setCurrentBoardTheme(selectedTheme);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      board == undefined ||
      boardSize != board.length ||
      selectedTheme != currentBoardTheme
    ) {
      fetchBoardSquares();
    }
  }, [boardSize, selectedTheme]);

  const createBoard = () => {
    setLoading(true);
    fetch("/bingo_board", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        board: board,
        userId: authInfo.currentFirebaseUser?.uid,
        title: title,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        navigate(`/board/${data}`);
      });
  };

  const onSave = (board: IBingoSquare[][], title: string) => {
    let editedBoard = [...board];
    for (let row = 0; row < editedBoard.length; row++) {
      for (let col = 0; col < editedBoard.length; col++) {
        let elem = document.getElementById(`content_${row}.${col}`);
        editedBoard[row][col].title = elem?.innerText ?? "";
      }
    }
    setBoard(editedBoard);
    setTitle(title);
    setTimeout(() => setIsEditing(false), 50); // required on mobile to prevent screen dimensions from being calculated with soft keyboard up
  };

  return (
    <div className="m-4">
      {loading || !board ? (
        <div
          className={`flex flex-col items-center justify-center full-screen-height-minus-secondary-logo `}
        >
          <LoadingDeb />
        </div>
      ) : (
        <Fragment>
          {/* <p className="text-center mb-4 text-sm uppercase boardWidth">We've generated a board for you, but feel free to customize the board name and squares. Once you create the board you can invite more players and add Wild Balls rules.</p> */}
          {isEditing ? (
            <EditBoardView title={title} board={board} onSave={onSave} />
          ) : (
            <ChooseSquaresViewPage
              title={title}
              board={board}
              createBoard={createBoard}
              setIsEditing={setIsEditing}
              refreshBoardSquares={fetchBoardSquares}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};
