import { useNavigate } from "react-router-dom";
import { StandardButton } from "../components/buttons/StandardButton";
import { HomePageLogo } from "../logos/HomePageLogo";
import { SecondaryLogo } from "../logos/SecondaryLogo";
import { useAuth } from "../contexts/AuthContext";

export const PageNotFound = () => {
  const navigate = useNavigate();
  const authInfo = useAuth();
  return (
    <div className="flex flex-col full-screen-height items-center justify-center">
      <SecondaryLogo />
      <div className="flex flex-col items-center">
        <img className="m-4 w-20" src="/assets/avatars/BBs_Deb_NoShow.png" />
        <h1 className="m-2 text-4xl text-center">OH YIKES!</h1>
        <p className="m-4 mx-[6%] text-xl font-hoss font-light uppercase text-center">
          We can't find the page you're looking for
        </p>
      </div>
      <StandardButton
        className="m-6 bg-fuschia px-4 py-1 text-cream text-2xl"
        text="GO HOME"
        onClick={() =>
          authInfo.currentUserInfo ? navigate("/boards") : navigate("/")
        }
      />
    </div>
  );
};
