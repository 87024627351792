import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadingDeb } from "../loading/loadingDeb";

const WithBoardCode = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const { boardId, boardCode } = params;
  const [boardWatchCodeValidated, setBoardWatchCodeValidated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/bingo_board/validate_watch_code/${boardId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        watchCode: boardCode,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setBoardWatchCodeValidated(data);
        setLoading(false);
        // TODO: error handling
      });
  }, []);
  return loading ? (
    <LoadingDeb />
  ) : boardWatchCodeValidated ? (
    children
  ) : (
    <Navigate to="/" />
  );
};

export default WithBoardCode;
