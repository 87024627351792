import { DateTime } from "luxon";

export interface IBingoSquareEntry {
  name: string;
  details: string;
  date: DateTime;
  dated: boolean;
  wildBall: boolean;
}

export const defaultBingoSquareEntry = (): IBingoSquareEntry => {
  return {
    name: "",
    details: "",
    date: DateTime.now(),
    dated: false,
    wildBall: false
  };
};
