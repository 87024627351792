import { BoardThumbnailImage } from "./BoardThumbnailImage";

interface BoardSizeThumbnailProps {
  boardSize: number;
  squareSize: string;
  visible: boolean;
}

export const BoardSizeThumbnail = (props: BoardSizeThumbnailProps) => {
  const { boardSize, squareSize, visible } =
    props;
  return (
    <div
      hidden={!visible}
      className={"justify-center items-center"}
    >
      <BoardThumbnailImage
        title=""
        // title={`${boardSize}x${boardSize}`}
        size={boardSize}
        className={`bg-cream ${squareSize}`}
      />
    </div>
  );
};
