import * as firebaseAuth from "firebase/auth";
import * as firebaseApp from "../contexts/firebase";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  ConfirmationResult,
  AuthError,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { StandardButton } from "../components/buttons/StandardButton";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { SecondaryLogo } from "../logos/SecondaryLogo";

export const SignInPage = () => {
  const navigate = useNavigate();
  const authInfo = useAuth();
  const params = useParams();

  useEffect(() => {
    // if user is logged in and already exists
    if (authInfo.currentFirebaseUser && authInfo.currentUserInfo) {
      if (params.inviteCode && params.joinBoardId) {
        navigate(`/join-board/${params.joinBoardId}/${params.inviteCode}`);
      } else {
        navigate("/boards");
      }
    }
    // if user just logged in for the first time
    else if (authInfo.currentFirebaseUser) {
      navigate("/account/newUser");
    }
  }, [authInfo.currentFirebaseUser, authInfo.currentUserInfo]);

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<firebaseAuth.ApplicationVerifier>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const auth = getAuth();

  const setInvisibleVerifier = () => {
    if (!recaptchaVerifier) {
      setRecaptchaVerifier(
        new RecaptchaVerifier(auth, "recaptcha", {
          size: "invisible",
          callback: (response: any) => {
            // console.log(response);
          },
          "expired-callback": () => {},
        })
      );
    }
  };

  useEffect(() => {
    setInvisibleVerifier();
  }, []);

  const signIn = () => {
    if (recaptchaVerifier) {
      signInWithPhoneNumber(auth, "+1" + phoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          setConfirmationResult(confirmationResult);
        })
        .catch((error: AuthError) => {
          // console.log(error);
          setErrorMessage(error.message); //TODO: error handling
        });
    }
  };

  const confirm = () => {
    if (confirmationResult) {
      confirmationResult
        .confirm(confirmationCode)
        .then((result) => {
          // console.log(result);
        })
        .catch((error) => {
          alert(error); //TODO: error handling
        });
    }
  };

  return (
    <div
      className={`flex flex-col items-center justify-center full-screen-height`}
    >
      <SecondaryLogo />
      {!confirmationResult ? (
        <div>
          <h1 className="text-4xl text-center mb-6">SIGN IN OR SIGN UP</h1>
          <div>
            <form
              className={`flex flex-col items-center justify-center`}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="text-2xl font-hoss font-light">
                +1
                <input
                  className="w-[80%] input m-3 px-1 border-b border-solid bg-transparent text-center"
                  type="tel"
                  pattern=".{10,10}"
                  value={phoneNumber}
                  placeholder="XXXXXXXXX"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                ></input>
                <div id="recaptcha"></div>
                {errorMessage && (
                  <div className="text-center font-hoss text-sm font-light text-firetruck flex-col">
                    {errorMessage}
                  </div>
                )}
              </div>
              <StandardButton
                text="CONFIRM"
                onClick={signIn}
                className="mt-6 text-xl bg-orange py-1 px-4 text-cream"
              />
              <p className="w-[200px] text-center m-6 font-hoss font-light">
                YOU'LL RECEIVE A VERIFICATION CODE AT THIS NUMBER
              </p>
            </form>
          </div>
        </div>
      ) : (
        <div>
          <h1 className="text-4xl text-center mb-6">VERIFICATION CODE</h1>
          <div className={`flex flex-col items-center justify-center`}>
            <input
              placeholder="XXXXXX"
              type="number"
              className="input text-2xl m-3 px-1 border-b border-solid bg-transparent font-hoss font-light text-center"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            ></input>
            <StandardButton
              text="LOGIN"
              onClick={confirm}
              className="mt-6 text-xl bg-orange py-1 px-4 text-cream"
            />
          </div>
        </div>
      )}
    </div>
  );
};
