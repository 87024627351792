import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const WithSignupFlowRoute = ({ children }) => {
  const authInfo = useAuth();

  if (authInfo.currentFirebaseUser) {
    return children;
  }

  return <Navigate to="/login" />;
};

export default WithSignupFlowRoute;
