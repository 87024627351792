import { useNavigate, useParams } from "react-router-dom";
import { SecondaryLogo } from "../logos/SecondaryLogo";
import { MenuButton } from "../components/buttons/MenuButton";
import { MainBoardView } from "../boardViews/MainBoardView";
import { Fragment, useEffect, useState } from "react";
import { MenuPage } from "../menu/MenuPage";
import { IBingoSquare } from "../interfaces/IBingoSquare";
import { IBingoBoard } from "../interfaces/IBingoBoard";
import { LoadingDeb } from "../loading/loadingDeb";
import {
  MainBoardPlayersTray,
  MainBoardPlayersTrayVisibilityControl,
} from "./MainBoardPlayersTray";
import { IMainBoardPlayersTrayOption } from "../interfaces/IMainBoardPlayersTrayOption";
import { IBingoSquareEntry } from "../interfaces/IBingoSquareEntry";
import { processBoardFromServer } from "../utils/serverHelpers";
import { shuffledPositionsForBoard } from "../utils/helpers";
import { useAuth } from "../contexts/AuthContext";
import { MainBoardSquareBottomSheet } from "./bottomsheets/MainBoardSquareBottomSheet";
import { DateTime } from "luxon";
import { MenuBar } from "./menubar/MenuBar";
import { IUserShortInfo } from "../interfaces/IUserShortInfo";
import { EditBoardPage } from "./edit/EditBoardPage";
import { useScreenDimensions } from "../hooks/useScreenDimensions";

interface MainBoardPageProps {}

export const MainBoardPage = (_props: MainBoardPageProps) => {
  const { boardId, inviteCode } = useParams();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [board, setBoard] = useState<IBingoBoard>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] =
    useState<IMainBoardPlayersTrayOption>({
      user: null,
      visibilityControl: MainBoardPlayersTrayVisibilityControl.ALL,
    });
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedSquare, setSelectedSquare] = useState<IBingoSquare | null>(
    null
  );
  const [boardSquarePositions, setBoardSquarePositions] = useState<any[]>();
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const authInfo = useAuth();
  const screenDims = useScreenDimensions();

  useEffect(() => {
    setLoading(true);
    if (boardId) {
      fetch(`/bingo_board/${encodeURIComponent(boardId)}`)
        .then((res) => res.json())
        .then((data) => {
          if (!data.squares) {
            return;
          }
          const processedSquares = processBoardFromServer(data.squares);
          setBoard({ ...data, squares: processedSquares });
          setBoardSquarePositions(shuffledPositionsForBoard(data.size));
          setLoading(false);
        });
    }
  }, [boardId]);

  const onSaveSquareEntry = (
    userId: string,
    square: IBingoSquare,
    newSquareEntry: IBingoSquareEntry
  ) => {
    if (!board) {
      return;
    }
    const boardId = board?.id;
    fetch(`/square_entries/${boardId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        entry: {
          ...newSquareEntry,
          date: newSquareEntry.date?.toFormat("yyyy-MM-dd") ?? DateTime.now(),
        },
        row: square.row,
        col: square.col,
        userId: userId,
      }),
    }).then((_res) => {
      if (board.squares) {
        let updatedBoard = [...board.squares];
        updatedBoard[square.row][square.col].entries.set(
          userId,
          newSquareEntry
        );
        setBoard({ ...board, squares: updatedBoard });
      }
      setDialogOpen(false);
    });
  };

  const onDeleteSquareEntry = (userId: string, square: IBingoSquare) => {
    if (!board) {
      return;
    }
    const boardId = board?.id;
    fetch(`/square_entries/${boardId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        row: square.row,
        col: square.col,
        userId: userId,
      }),
    }).then((_res) => {
      if (board.squares) {
        let updatedBoard = [...board.squares];
        updatedBoard[square.row][square.col].entries.delete(userId);
        setBoard({ ...board, squares: updatedBoard });
      }
      setDialogOpen(false);
    });
  };

  const onClickSquare = (square: IBingoSquare) => {
    setSelectedSquare(square);
    setDialogOpen(true);
  };

  const isViewerPartOfBoard = () => {
    const players = board?.players;
    const currentUserId = authInfo.currentUserInfo?.id;
    if (players && currentUserId) {
      return players
        .map((player: IUserShortInfo) => player.id)
        .includes(currentUserId);
    }
    return false;
  };

  const viewerPartOfBoard = isViewerPartOfBoard();

  return menuOpen ? (
    <MenuPage setMenuOpen={setMenuOpen} />
  ) : (
    <div
      className={`${
        viewerPartOfBoard &&
        "full-screen-height-minus-menu-bar relative overflow-scroll"
      }`}
    >
      <SecondaryLogo />
      <MenuButton onClick={() => setMenuOpen(true)} />
      {!loading && board && board.squares ? (
        <div className={`flex flex-col items-center relative top-[100px]`}>
          {inEditMode ? (
            <EditBoardPage
              setInEditMode={setInEditMode}
              board={board}
              setLoading={setLoading}
            />
          ) : (
            <Fragment>
              <div className="text-4xl mb-4 text-center">
                {board.title.toUpperCase()}
              </div>
              <MainBoardPlayersTray
                users={board.players}
                selectedOption={selectedOption}
                onSelectOption={setSelectedOption}
              />
              {selectedSquare && (
                <MainBoardSquareBottomSheet
                  open={dialogOpen}
                  square={selectedSquare}
                  handleExit={() => setDialogOpen(false)}
                  selectedTrayOption={selectedOption}
                  onSaveSquareEntry={onSaveSquareEntry}
                  onDeleteSquareEntry={onDeleteSquareEntry}
                />
              )}
              <MainBoardView
                board={board.squares}
                selectedTrayOption={selectedOption}
                players={board.players}
                onSaveSquareEntry={onSaveSquareEntry}
                onClickSquare={onClickSquare}
                boardSquarePositions={boardSquarePositions}
              />
            </Fragment>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center full-screen-height">
          <LoadingDeb />
        </div>
      )}
      {board && viewerPartOfBoard && (
        <MenuBar
          board={board}
          inEditMode={inEditMode}
          setInEditMode={setInEditMode}
          selectedUser={selectedOption.user ?? undefined}
        />
      )}
    </div>
  );
};
