import { MenuButton } from "../components/buttons/MenuButton";
import { SecondaryLogo } from "../logos/SecondaryLogo";
import { SpectateBoardEntry } from "./SpectateBoardEntry";

export const SpectatorHomePage = () => {
  return (
    <div>
      {/* <MenuButton onClick={() => setMenuOpen(true)} /> */}
      <SecondaryLogo />
      <div className={`flex flex-col justify-center relative top-[100px]`}>
        <h1 className="text-5xl m-8 self-center">STALKER MODE</h1>
        <SpectateBoardEntry />
      </div>
    </div>
  );
};
