import { DateTime } from "luxon";
import { IBingoSquare } from "../interfaces/IBingoSquare";

export const processBoardFromServer = (squares: any) => {
  const processedSquares = squares.map((row: IBingoSquare[]) =>
    row.map((square) => {
      const sq = {
        ...square,
        entries: new Map(Object.entries(square.entries)),
      };
      sq.entries.forEach((user) => {
        user.date = DateTime.fromFormat(user.date, "yyyy-MM-dd");
      });
      return sq;
    })
  );
  return processedSquares;
};
