import { SwipeableDrawer } from "@mui/material";

interface StandardBottomSheetProps {
  open: boolean;
  handleExit: () => void;
  title: string;
  content?: React.ReactNode;
  imgUrl?: string;
  className?: string;
}

export const StandardBottomSheet = (props: StandardBottomSheetProps) => {
  const { open, handleExit, title, content, imgUrl, className } = props;

  const closeButton = (
    <div
      className="cursor-pointer absolute top-0 right-3 font-hoss text-3xl"
      onClick={handleExit}
    >
      x
    </div>
  );

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={handleExit}
      onOpen={() => null}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      classes={{
        paper: "dialog-background",
      }}
    >
      {imgUrl && (
        <img
          src={`/assets/${imgUrl}`}
          className="w-16 opacity-80 absolute -top-8 left-[50%] -translate-x-[50%] z-10"
        ></img>
      )}
      {closeButton}
      <div
        className={`bg-cream flex flex-col p-6 border-chocolate border-t border-x rounded-t-[11px] ${className}`}
      >
        <h2
          className={`text-mencken uppercase text-3xl text-center mb-2 ${
            imgUrl && "mt-6"
          }`}
        >
          {title}
        </h2>
        {content}
      </div>
    </SwipeableDrawer>
  );
};
