import { StandardButton } from "../../../components/buttons/StandardButton";
import { IUserShortInfo } from "../../../interfaces/IUserShortInfo";
import { getWildBallPathForAvatarId } from "../../../utils/helpers";

interface WildBallsRedeemViewProps {
  redeemablePoints: number;
  user: IUserShortInfo;
  onExit: () => void;
  onRedeem: () => void;
}

export const WildBallsRedeemView = (props: WildBallsRedeemViewProps) => {
  const { redeemablePoints, user, onExit, onRedeem } = props;
  const redeemableBalls = Math.floor(redeemablePoints / 10);

  return (
    <div className="items-center text-russet flex flex-col w-[100%] font-hoss font-light text-l uppercase text-center">
      <p className="m-2">
        You have <b>+{redeemablePoints} </b>points = <b>{redeemableBalls} </b>
        {redeemableBalls > 1 ? "Wild Balls" : "Wild Ball"}
      </p>
      <p className="m-2">You may redeem one Wild Ball at a time</p>
      <img
        className="w-20 m-6"
        src={`/assets${getWildBallPathForAvatarId(user.avatarId)}`}
      />
      <p className="m-4">
        This can complete any square on your board, but cannot be moved once
        placed
      </p>
      <div className="flex flex-row w-[100%]">
        <StandardButton
          className="grow bg-cream text-xl py-1 px-3 m-1"
          text="MAYBE LATER"
          onClick={onExit}
        />
        <StandardButton
          className="grow bg-cream text-xl py-1 px-3 m-1"
          text="REDEEM NOW"
          onClick={onRedeem}
        />
      </div>
    </div>
  );
};
