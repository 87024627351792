import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HomePageEntryOptions } from "./homepage/HomePageEntryOptions";
import { useScreenDimensions } from "./hooks/useScreenDimensions";
import { YourBoards } from "./boards/YourBoards";
import { MainBoardPage } from "./board/MainBoardPage";
import { AuthProvider } from "./contexts/AuthContext";
import { SignInPage } from "./accounts/SignInPage";
import WithPrivateRoute from "./utils/WithPrivateRoute";
import { JoinBoardPage } from "./board/JoinBoardPage";
import WithPrivateRoutePassParams from "./utils/WithPrivateRoutePassParams";
import { PageNotFound } from "./errors/PageNotFound";
import { CreateBoardPage } from "./boardCreation/CreateBoardPage";
import { CustomizeAccountPage } from "./accounts/CustomizeAccountPage";
import { SpectatorHomePage } from "./spectator/SpectatorHomePage";
import WithBoardCode from "./utils/WithBoardCode.js";
import { HowToPlay } from "./menu/HowToPlay";
import { HomePage } from "./homepage/HomePage";
import WithSignupFlowRoute from "./utils/WithSignupFlowRoute";

function App() {
  const _screenDims = useScreenDimensions();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePageEntryOptions />,
    },
    {
      path: "/login/:joinBoardId?/:inviteCode?",
      element: <SignInPage />,
    },
    {
      path: "/boards",
      element: (
        <WithPrivateRoute>
          <YourBoards />
        </WithPrivateRoute>
      ),
    },
    {
      path: "/account/newUser",
      element: (
        <WithSignupFlowRoute>
          <CustomizeAccountPage isNewUser={true} />
        </WithSignupFlowRoute>
      ),
    },
    {
      path: "/board/:boardId",
      element: (
        <WithPrivateRoute>
          <MainBoardPage />
        </WithPrivateRoute>
      ),
    },
    {
      path: "/watch-board/:boardId/:boardCode",
      element: (
        <WithBoardCode>
          <MainBoardPage />
        </WithBoardCode>
      ),
    },
    {
      path: "/join-board/:boardId?/:inviteCode?",
      element: (
        <WithPrivateRoutePassParams>
          <JoinBoardPage />
        </WithPrivateRoutePassParams>
      ),
    },
    {
      path: "/create-board",
      element: (
        <WithPrivateRoute>
          <CreateBoardPage />
        </WithPrivateRoute>
      ),
    },
    {
      path: "/spectate",
      element: <SpectatorHomePage />,
    },
    { path: "/rules", element: <HowToPlay /> },
    { path: "*", element: <PageNotFound /> },
  ]);

  return (
    <React.StrictMode>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
