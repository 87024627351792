import { useNavigate } from "react-router-dom";
import { BoardCreationView } from "../boardViews/BoardCreationView";
import { StandardButton } from "../components/buttons/StandardButton";
import { IBingoSquare } from "../interfaces/IBingoSquare";
import { CreateBoardStage } from "./CreateBoardPage";

interface ChooseSquaresViewPageProps {
  title: string;
  board: IBingoSquare[][];
  createBoard: () => void;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  refreshBoardSquares: () => void;
}

export const ChooseSquaresViewPage = (props: ChooseSquaresViewPageProps) => {
  const { title, board, createBoard, setIsEditing, refreshBoardSquares } =
    props;

  return (
    <div className={`flex flex-col`}>
      <p className="font-hoss font-light uppercase mb-4 text-center boardWidth">
        We've generated a board for you! Now make it your own. Or{" "}
        <span
          className="underline cursor-pointer"
          onClick={refreshBoardSquares}
        >
          generate new squares
        </span>
        .
      </p>
      <h1 className="font-mencken text-center text-4xl mb-4">
        {title.toUpperCase()}
      </h1>
      <BoardCreationView board={board} />
      <div className="flex flex-row">
        <StandardButton
          text="EDIT"
          onClick={() => setIsEditing(true)}
          className="py-2 my-4 mr-4 grow text-xl bg-fuschia text-cream"
        />
        <StandardButton
          className="py-2 my-4 grow text-xl bg-teal text-cream"
          text="LET'S PLAY"
          onClick={createBoard}
        />
      </div>
    </div>
  );
};
