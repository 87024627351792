import { useState } from "react";
import { BaseBoardView } from "../../../boardViews/BaseBoardView";
import { StandardButton } from "../../../components/buttons/StandardButton";
import { IBingoSquare } from "../../../interfaces/IBingoSquare";
import { IUserShortInfo } from "../../../interfaces/IUserShortInfo";

interface RedeemWildBallsBoardProps {
  board: IBingoSquare[][];
  onRedeem: (square: IBingoSquare) => void;
  user: IUserShortInfo;
}

export const RedeemWildBallsBoard = (props: RedeemWildBallsBoardProps) => {
  const { board, onRedeem, user } = props;
  const [selectedSquare, setSelectedSquare] = useState<IBingoSquare>();

  const onClickSquare = (square: IBingoSquare) => {
    setSelectedSquare(square);
  };

  return (
    <div className="flex flex-col w-[100%] items-center">
      <BaseBoardView
        board={board}
        selectedTrayOption={{ user: user, visibilityControl: null }}
        onClickSquare={onClickSquare}
        squareClassName="cursor-pointer"
        players={[user]}
        selectedWildBallSquare={selectedSquare}
      />
      <div className="flex flex-row w-[100%] mt-4">
        <StandardButton
          disabled={!selectedSquare}
          className="grow bg-canteloupe text-xl py-1 px-3 m-1"
          text="CONFIRM"
          onClick={() => selectedSquare && onRedeem(selectedSquare)}
        />
      </div>
    </div>
  );
};
