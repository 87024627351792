import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { StandardButton } from "../components/buttons/StandardButton";
import { StandardDialog } from "../components/StandardDialog";

interface LogOutDialogProps {
  open: boolean;
  handleExit: () => void;
}

export const LogOutDialog = (props: LogOutDialogProps) => {
  const { open, handleExit } = props;
  const navigate = useNavigate();
  const authInfo = useAuth();

  const content = (
    <div className="flex-row flex">
      <StandardButton
        className="bg-red text-xl m-2 py-1 px-4 text-cream"
        text="YES"
        onClick={() => {
          authInfo.logout();
          navigate("/");
        }}
      />
      <StandardButton
        className="bg-teal text-xl m-2 py-1 px-4 text-cream"
        text="NO"
        onClick={handleExit}
      />
    </div>
  );

  return (
    <StandardDialog
      open={open}
      handleExit={handleExit}
      title="Are you sure you want to log out?"
      content={content}
    />
  );
};
