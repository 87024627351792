interface CopyableTextProps {
  text: string;
  id: string;
}

export const CopyableText = (props: CopyableTextProps) => {
  const { text, id } = props;
  return (
    <div className="w-[100%] items-center flex flex-row text-xl py-1 pl-4 bg-cream border border-solid border-chocolate rounded-2xl font-hoss font-light">
      <div
        className="grow overflow-x-scroll whitespace-nowrap text-chocolate"
        id={id}
      >
        {text}
      </div>
      <div
        onClick={() => {
          navigator.clipboard.writeText(text);
        }}
        className="cursor-pointer mx-2 bg-teal border border-solid border-chocolate rounded-2xl px-4 text-cream font-normal hover:opacity-60"
      >
        COPY
      </div>
    </div>
  );
};
