import { IBingoSquare } from "../interfaces/IBingoSquare";
import { BaseBoardView } from "./BaseBoardView";
import { useState } from "react";
import { MainBoardSquareDialog } from "../board/dialog/MainBoardSquareDialog";
import { IMainBoardPlayersTrayOption } from "../interfaces/IMainBoardPlayersTrayOption";
import { IUserShortInfo } from "../interfaces/IUserShortInfo";
import { IBingoSquareEntry } from "../interfaces/IBingoSquareEntry";

interface MainBoardViewProps {
  board: IBingoSquare[][];
  selectedTrayOption: IMainBoardPlayersTrayOption;
  players: IUserShortInfo[];
  onSaveSquareEntry: (
    userId: string,
    square: IBingoSquare,
    newSquareEntry: IBingoSquareEntry
  ) => void;
  onClickSquare: (square: IBingoSquare) => void;
  boardSquarePositions?: { left: string; top: string }[][][];
}

export const MainBoardView = (props: MainBoardViewProps) => {
  const {
    board,
    selectedTrayOption,
    players,
    onSaveSquareEntry,
    onClickSquare,
    boardSquarePositions,
  } = props;

  return (
    <div className="p-6">
      <BaseBoardView
        board={board}
        selectedTrayOption={selectedTrayOption}
        onClickSquare={onClickSquare}
        squareClassName="cursor-pointer"
        players={players}
        boardSquarePositions={boardSquarePositions}
      />
    </div>
  );
};
