import { IUserShortInfo } from "../interfaces/IUserShortInfo";
import { getAvatarPathForId } from "../utils/helpers";
import { IMainBoardPlayersTrayOption } from "../interfaces/IMainBoardPlayersTrayOption";

interface MainBoardPlayersTrayProps {
  users: IUserShortInfo[];
  selectedOption: IMainBoardPlayersTrayOption;
  onSelectOption: (option: IMainBoardPlayersTrayOption) => void;
}

export enum MainBoardPlayersTrayVisibilityControl {
  ALL,
  NONE,
}

export const MainBoardPlayersTray = (props: MainBoardPlayersTrayProps) => {
  const { users, selectedOption, onSelectOption } = props;

  const getTrayOption = (
    key: string,
    imgUrl: string,
    user: IUserShortInfo | null,
    control: MainBoardPlayersTrayVisibilityControl | null
  ) => {
    return (
      <div key={key} className="flex flex-col items-center mx-2 max-w-[20%]">
        <img
          onClick={() =>
            onSelectOption({ user: user, visibilityControl: control })
          }
          className={`w-10 m-1 z-10 cursor-pointer ${
            (user && user == selectedOption.user) ||
            (control != null && control == selectedOption.visibilityControl)
              ? "opacity-1"
              : "opacity-40"
          }`}
          src={`/assets/avatars/${imgUrl}`}
        />
        <div
          className={`font-hoss font-light text-center truncate ${
            control != MainBoardPlayersTrayVisibilityControl.ALL &&
            "truncate w-[100%]"
          } text-sm`}
        >
          {user ? user.displayName : null}
          {control != null
            ? control == MainBoardPlayersTrayVisibilityControl.ALL
              ? "view all"
              : "hide all"
            : null}
        </div>
      </div>
    );
  };

  return (
    <div className="relative boardWidth">
      <div className="font-hoss bg-avocado text-center nowrap min-w-[16ch] flex flex-col p-2 font-light absolute left-2/4 -translate-x-2/4 -top-5">
        SELECT YOUR VIEW
      </div>
      <div className="border border-solid rounded-xl border-black max-h-[100px] overflow-scroll min-w-[100%]">
        <div className="flex flex-row m-2 flex uppercase flex-wrap justify-center">
          {getTrayOption(
            "players_tray_view_all",
            "BBs_Deb_ViewAll.png",
            null,
            MainBoardPlayersTrayVisibilityControl.ALL
          )}
          {users.map((user: IUserShortInfo) => {
            return getTrayOption(
              `players_tray_${user.id}`,
              getAvatarPathForId(user.avatarId),
              user,
              null
            );
          })}
          {getTrayOption(
            "players_tray_no_show",
            "BBs_Deb_NoShow.png",
            null,
            MainBoardPlayersTrayVisibilityControl.NONE
          )}
        </div>
      </div>
    </div>
  );
};
