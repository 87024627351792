import { IBingoSquare } from "../interfaces/IBingoSquare";
import { BaseBoardView } from "./BaseBoardView";

interface BoardCreationViewProps {
  board: IBingoSquare[][];
}

export const BoardCreationView = (props: BoardCreationViewProps) => {
  const { board } = props;

  return <BaseBoardView board={board}/>;
};
