import { useState } from "react";
import { StandardButton } from "../components/buttons/StandardButton";
import { useNavigate } from "react-router-dom";

export const SpectateBoardEntry = () => {
  const [watchBoardCode, setWatchBoardCode] = useState<string>("");
  const navigate = useNavigate();

  const onEnterWatchCode = () => {
    const watchCode = watchBoardCode;
    fetch(`/bingo_board/watch_code`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        watchCode: watchCode,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0) {
          navigate(`/watch-board/${data}/${watchCode}`);
        }
        // TODO: show error message if board doesn't exist
        // TODO: error handling
      });
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="font-hoss font-light text-2xl m-4 text-center">
        FIND A FRIEND'S BOARD
      </h2>
      <div className="p-4 items-center flex flex-col">
        <img src="/assets/lock.png" className="w-[30px]" />
        <h1 className="font-mencken text-4xl m-4">BOARD CODE</h1>
        <input
          placeholder="XXXXXX"
          type="text"
          className="input text-xl m-2 px-1 border-b border-solid bg-transparent font-hoss font-light text-center uppercase"
          value={watchBoardCode}
          onChange={(e) => setWatchBoardCode(e.target.value)}
        ></input>
        <StandardButton
          text="ENTER"
          onClick={onEnterWatchCode}
          className="mt-6 text-xl bg-orange py-1 px-4"
          disabled={watchBoardCode == ""}
        />
      </div>
    </div>
  );
};
