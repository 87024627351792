import { StandardDialog } from "../../../components/StandardDialog";
import { useNavigate } from "react-router-dom";
import { StandardButton } from "../../../components/buttons/StandardButton";

interface DeleteBoardDialogProps {
  open: boolean;
  handleExit: () => void;
  boardId: string;
}

export const DeleteBoardDialog = (props: DeleteBoardDialogProps) => {
  const { open, handleExit, boardId } = props;
  const navigate = useNavigate();

  const deleteBoard = () => {
    fetch(`/bingo_board/${boardId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((_data) => navigate("/boards"));
  };

  const content = (
    <div className="flex flex-col items-center">
      <p className="font-hoss font-light uppercase">
        This action cannot be undone
      </p>
      <div className="flex-row flex mt-2">
        <StandardButton
          className="bg-red text-xl m-2 py-1 px-4 text-cream"
          text="YES"
          onClick={deleteBoard}
        />
        <StandardButton
          className="bg-teal text-xl m-2 py-1 px-4 text-cream"
          text="NO"
          onClick={handleExit}
        />
      </div>
    </div>
  );

  return (
    <StandardDialog
      open={open}
      handleExit={handleExit}
      title="Are you sure you want to delete your board?"
      content={content}
    />
  );
};
