import { StandardBottomSheet } from "../../components/StandardBottomSheet";
import { IBingoBoard } from "../../interfaces/IBingoBoard";
import { HomePageViewStateToggle } from "../../boards/HomePageViewStateToggle";
import { HomePageViewState } from "../../boards/YourBoards";
import { useState } from "react";
import { CopyableText } from "../../components/CopyableLink";

interface InviteFriendsBottomSheetProps {
  open: boolean;
  handleExit: () => void;
  board: IBingoBoard;
}

export const InviteFriendsBottomSheet = (
  props: InviteFriendsBottomSheetProps
) => {
  const { open, handleExit, board } = props;
  const [viewState, setViewState] = useState<HomePageViewState>(
    HomePageViewState.PLAY
  );

  const playLink = (
    <CopyableText
      text={`https://www.datingbingo.club/join-board/${board.id}/${board.inviteCode}`}
      id="playLink"
    />
  );
  const watchLink = (
    <CopyableText
      text={`https://www.datingbingo.club/watch-board/${board.id}/${board.watchCode}`}
      id="watchLink"
    />
  );
  const watchCode = <CopyableText text={`${board.watchCode}`} id="watchCode" />;

  return (
    <StandardBottomSheet
      open={open}
      handleExit={handleExit}
      title="INVITE FRIENDS TO..."
      content={
        <div className="flex flex-col mt-2">
          <HomePageViewStateToggle
            viewState={viewState}
            setViewState={setViewState}
            selectedClassName="bg-cream"
            unselectedClassName="bg-avocado text-darkgray"
          />
          {viewState == HomePageViewState.PLAY ? (
            <div>
              <p className="font-hoss font-light text-cream text-center m-4">
                SHARE YOUR PLAY LINK
              </p>
              {playLink}
            </div>
          ) : (
            <div>
              <p className="font-hoss font-light text-cream text-center m-4">
                SHARE YOUR WATCH LINK
              </p>
              {watchLink}
              <p className="font-hoss font-light text-cream text-center m-4">
                SHARE YOUR WATCH BOARD CODE
              </p>
              <p className="uppercase">{watchCode}</p>
            </div>
          )}
        </div>
      }
      className={`text-cream ${
        viewState == HomePageViewState.PLAY ? "bg-fuschia" : "bg-grape"
      }`}
    />
  );
};
