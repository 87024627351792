import { StandardButton } from "../../components/buttons/StandardButton";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { StandardDialog } from "../../components/StandardDialog";

interface JoinBoardDialogProps {
  open: boolean;
  handleExit: () => void;
  boardId: string;
}

export const JoinBoardDialog = (props: JoinBoardDialogProps) => {
  const { open, handleExit, boardId } = props;
  const authInfo = useAuth();
  const navigate = useNavigate();

  const joinBoard = () => {
    fetch(`/bingo_board/add_user/${encodeURIComponent(boardId)}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: authInfo.currentUserInfo?.id,
      }),
    })
      .then((res) => res.json())
      .then((_data) => {
        navigate(`/board/${boardId}`);
      });
  };

  return (
    <StandardDialog
      open={open}
      handleExit={handleExit}
      title="YOU'VE BEEN INVITED TO PLAY!"
      className="bg-teal text-cream"
      content={
        <StandardButton
          text="JOIN BOARD"
          onClick={joinBoard}
          className="bg-avocado px-3 py-1 text-xl text-chocolate"
        />
      }
    />
  );
};
