import { useState } from "react";
import { EditBoardView } from "../../components/EditBoardView";
import { StandardButton } from "../../components/buttons/StandardButton";
import { IBingoBoard } from "../../interfaces/IBingoBoard";
import { IBingoSquare } from "../../interfaces/IBingoSquare";
import { DeleteBoardDialog } from "../settings/dialogs/DeleteBoardDialog";
import { useNavigate } from "react-router-dom";

interface EditBoardPageProps {
  board: IBingoBoard;
  setInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditBoardPage = (props: EditBoardPageProps) => {
  const { board, setInEditMode, setLoading } = props;
  const [deleteBoardDialogOpen, setDeleteBoardDialogOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const onSave = (board: IBingoSquare[][], title: string) => {
    let editedBoard = JSON.parse(JSON.stringify(board)); //needed to safely deep-copy board
    for (let row = 0; row < editedBoard.length; row++) {
      for (let col = 0; col < editedBoard.length; col++) {
        let elem = document.getElementById(`content_${row}.${col}`);
        editedBoard[row][col] = {
          title: elem?.innerText ?? "",
          row: row,
          col: col,
          entries: new Map(),
        };
      }
    }
    editBoard(editedBoard, title);
  };

  const editBoard = async (boardSquares: IBingoSquare[][], title: string) => {
    setLoading(true);
    await fetch(`/bingo_board/edit/${board.id}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        board: boardSquares,
        title: title,
      }),
    })
      .then((res) => res.json())
      .then((_data) => {
        // setLoading(false);
        // TODO: error handling
        navigate(0); // reload with fresh board data
        // setInEditMode(false);
      });
  };

  return (
    <div className="flex flex-col items-center mx-6">
      <EditBoardView
        title={board.title}
        onSave={onSave}
        board={board.squares!}
        onBack={() => setInEditMode(false)}
      />
      <StandardButton
        text="DELETE BOARD"
        className="text-lg bg-firetruck px-2 my-6 text-cream"
        onClick={() => setDeleteBoardDialogOpen(true)}
      />
      <DeleteBoardDialog
        boardId={board.id}
        open={deleteBoardDialogOpen}
        handleExit={() => setDeleteBoardDialogOpen(false)}
      />
    </div>
  );
};
