import { useNavigate } from "react-router-dom";
import { BoardCreationView } from "../boardViews/BoardCreationView";
import { StandardButton } from "../components/buttons/StandardButton";
import { IBingoSquare } from "../interfaces/IBingoSquare";
import { useAuth } from "../contexts/AuthContext";
import { LoadingDeb } from "../loading/loadingDeb";
import { useState } from "react";

interface FinishBoardCreationPageProps {
  title: string;
  board: IBingoSquare[][] | undefined;
}

export const FinishBoardCreationPage = (
  props: FinishBoardCreationPageProps
) => {
  const { title, board } = props;
  const navigate = useNavigate();
  const authInfo = useAuth();
  const [loading, setLoading] = useState<boolean>();

  const createBoard = () => {
    setLoading(true);
    fetch("/bingo_board", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        board: board,
        userId: authInfo.currentFirebaseUser?.uid,
        title: title,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        navigate(`/board/${data}`);
      });
  };

  return (
    <div>
      {loading || !board ? (
        <div
          className={`flex flex-col items-center justify-center full-screen-height-minus-secondary-logo `}
        >
          <LoadingDeb />
        </div>
      ) : (
        <div className={`flex flex-col justify-center`}>
          <h1 className="font-mencken text-center text-4xl uppercase mb-6 ">
            {title}
          </h1>
          <BoardCreationView board={board} />
          <StandardButton
            className="mt-4 grow md:grow-0 text-xl bg-teal py-1"
            text="LET'S PLAY"
            onClick={createBoard}
          />
        </div>
      )}
    </div>
  );
};
