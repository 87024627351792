import { SetStateAction } from "react";
import { BoardSizeThumbnail } from "../boardThumbnail/BoardSizeThumbnail";
import { BoardThemeThumbnail } from "../boardThumbnail/BoardThemeThumbnail";
import { BoardThumbnail } from "../boardThumbnail/BoardThumbnail";
import { BoardThumbnailImage } from "../boardThumbnail/BoardThumbnailImage";
import {
  BoardTheme,
  CreateBoardStage,
  boardStages,
  getDescriptionForBoardTheme,
} from "./CreateBoardPage";
import { StandardButton } from "../components/buttons/StandardButton";

interface ChooseBoardThemePageProps {
  selectedTheme: BoardTheme;
  setSelectedTheme: React.Dispatch<React.SetStateAction<BoardTheme>>;
  boardStage: CreateBoardStage;
  setBoardStage: React.Dispatch<React.SetStateAction<CreateBoardStage>>;
}

export const ChooseBoardThemePage = (props: ChooseBoardThemePageProps) => {
  const { selectedTheme, setSelectedTheme, boardStage, setBoardStage } = props;
  const selectedStyle = "bg-fuschia60";

  return (
    <div className="flex flex-col justify-center p-4">
      <div className="flex-col">
        {Object.values(BoardTheme).map((theme) => (
          <div
            key={theme}
            onClick={() => setSelectedTheme(theme)}
            className={`m-4 p-[4px] cursor-pointer rounded-[6px] ${
              selectedTheme == theme ? selectedStyle : null
            }`}
          >
            <div className="rounded-[6px] border-chocolate border text-center font-mencken text-3xl bg-cream text-chocolate py-2 px-3">
              {theme.toUpperCase()}
              <p className="text-base font-hoss font-light uppercase text-center max-w-[50ch]">
                {getDescriptionForBoardTheme(theme)}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row justify-center">
        <img
          className="w-24 m-4 cursor-pointer"
          src="/assets/generate.png"
          onClick={() => setBoardStage(boardStage + 1)}
        />
      </div>
    </div>
  );
};
