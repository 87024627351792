import { useEffect, useState } from "react";
import { StandardButton } from "../../../components/buttons/StandardButton";
import { IWildBallRule } from "../../../interfaces/IWildBallRule";
import { TextareaAutosize } from "@mui/material";
import { StandardDialog } from "../../../components/StandardDialog";

interface WildBallsEditTableProps {
  rules: IWildBallRule[];
  onSave: (rules: IWildBallRule[]) => void;
  setRules: React.Dispatch<React.SetStateAction<IWildBallRule[]>>;
  boardId: string;
}

export const WildBallsEditTable = (props: WildBallsEditTableProps) => {
  const { rules, onSave, setRules, boardId } = props;
  const [selectedRuleIndex, setSelectedRuleIndex] = useState<number>(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [selectedRuleToDelete, setSelectedRuleToDelete] = useState<{
    index: number;
    ruleId?: string;
  }>({ index: 0 });

  const addNewRule = () => {
    const rulesLength = rules.length;
    setRules(
      rules.concat({ text: "", pointsValue: 1, id: Date.now().toString() })
    );
    setSelectedRuleIndex(rulesLength);
  };

  const showDeleteDialog = (index: number, ruleId?: string) => {
    setSelectedRuleToDelete({ index: index, ruleId: ruleId });
    setDeleteDialogOpen(true);
  };

  const deleteRule = async (rule: { index: number; ruleId?: string }) => {
    const { index, ruleId } = rule;
    let rulesCopy = [...rules];
    rulesCopy.splice(index, 1);
    setRules(rulesCopy);
    if (ruleId) {
      await fetch(`/wild_balls_rules/${boardId}/${ruleId}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((_data) => {
          // TODO: error handling
        });
    }
  };

  const cellClassName = "w-[100%] p-2 items-center text-center overflow-wrap";
  const selectedRuleClassName = "bg-russet10 rounded-[6px]";

  return (
    <div className="flex flex-col w-[100%]">
      <StandardDialog
        title="Delete rule for everyone?"
        open={deleteDialogOpen}
        handleExit={() => setDeleteDialogOpen(false)}
        content={
          <div className="flex-row flex">
            <StandardButton
              className="bg-red text-xl m-2 py-1 px-4 text-cream"
              text="YES"
              onClick={() => {
                deleteRule(selectedRuleToDelete);
                setDeleteDialogOpen(false);
              }}
            />
            <StandardButton
              className="bg-teal text-xl m-2 py-1 px-4 text-cream"
              text="NO"
              onClick={() => setDeleteDialogOpen(false)}
            />
          </div>
        }
      />
      <table className="font-hoss font-light text-cream text-lg uppercase w-[100%] max-h-[40vh] overflow-scroll block">
        <tr className="rounded-t-[6px] bg-russet font-mencken text-2xl w-[100%] sticky top-0">
          <td className={`rounded-tl-[6px] ${cellClassName}`}>RULE</td>
          <td className={`${cellClassName}`}>POINTS</td>
          <td className={`rounded-tr-[6px] ${cellClassName}`}>
            <div className="flex flex-col">
              <img
                className="min-w-[18px] max-w-[18px] m-2 opacity-0"
                src="/assets/trashIconCream.png"
              />
            </div>
          </td>
        </tr>
        {rules.length == 0 && (
          <div className="border-russet border w-[100%] p-4 text-center text-xl uppercase">
            No rules yet! Click below to add some.
          </div>
        )}
        <tbody className="text-base" id="rules_body">
          {rules.map((rule, i) => {
            return (
              <tr className="border-russet border" key={`row_` + i}>
                <td
                  className={`${cellClassName}`}
                  onClick={() => setSelectedRuleIndex(i)}
                >
                  <div
                    className={`min-w-[40vw] max-w-[40vw] ${
                      selectedRuleIndex == i && selectedRuleClassName
                    }`}
                  >
                    <TextareaAutosize
                      autoFocus={selectedRuleIndex == i}
                      maxRows={3}
                      id={`ruletext_${i}`}
                      className={`p-3 input bg-transparent uppercase text-russet text-start w-[100%] text-lg`}
                      value={rule.text}
                      onChange={(e) => {
                        let rulesCopy = [...rules];
                        rulesCopy[i].text = e.target.value;
                        setRules(rulesCopy);
                      }}
                    ></TextareaAutosize>
                  </div>
                </td>
                <td className={`${cellClassName} text-russet text-2xl `}>
                  <div className="flex items-center justify-center">
                    <span className="flex flex-row items-center justify-center rounded-[4px] border border-russet px-3">
                      <img
                        src="/assets/downCarat.png"
                        className="m-2 h-[8px]"
                        onClick={() => {
                          let rulesCopy = [...rules];
                          rulesCopy[i].pointsValue = rule.pointsValue - 1;
                          setRules(rulesCopy);
                        }}
                      ></img>
                      <span className="max-w-[40px] w-[40px]">
                        {rule.pointsValue > 0 && "+"}
                        {rule.pointsValue}
                      </span>
                      <img
                        src="/assets/upCarat.png"
                        className="m-2 h-[8px]"
                        onClick={() => {
                          let rulesCopy = [...rules];
                          rulesCopy[i].pointsValue = rule.pointsValue + 1;
                          setRules(rulesCopy);
                        }}
                      ></img>
                    </span>
                  </div>
                </td>
                <td className={`${cellClassName}`}>
                  <img
                    className="min-w-[18px] max-w-[18px] m-2"
                    src="/assets/trashIconRusset.png"
                    onClick={() => showDeleteDialog(i, rule.id)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex flex-col self-center w-[100%] h-[2px] bg-russet relative mb-6">
        <span
          onClick={addNewRule}
          className="unselectable absolute left-[50%] -translate-x-[50%] bottom-[50%] translate-y-[50%] bg-russet text-xl text-cream font-hoss rounded-[50%] w-[32px] h-[32px] flex flex-col text-center items-center justify-center align-middle"
        >
          +
        </span>
      </div>
      <div className="flex flex-col w-[100%]">
        <StandardButton
          text="DONE FOR NOW"
          className="text-russet grow my-2 py-1 bg-cream text-xl px-4"
          onClick={() => onSave(rules)}
        />
      </div>
    </div>
  );
};
