import { BoardSizeThumbnail } from "../boardThumbnail/BoardSizeThumbnail";
import { StandardButton } from "../components/buttons/StandardButton";
import { CreateBoardStage, boardStages } from "./CreateBoardPage";

interface ChooseBoardSizePageProps {
  selectedSize: number;
  setSelectedSize: React.Dispatch<React.SetStateAction<number>>;
  boardStage: CreateBoardStage;
  setBoardStage: React.Dispatch<React.SetStateAction<CreateBoardStage>>;
}

export const ChooseBoardSizePage = (props: ChooseBoardSizePageProps) => {
  const { selectedSize, setSelectedSize, boardStage, setBoardStage } = props;

  const getSubtitleForSize = (size: number) => {
    switch (size) {
      case 4:
        return "EASY PEASY LEMON SQUEEZY";
      case 5:
        return "CLASSIC";
      case 6:
        return "A CHALLENGE!";
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-row justify-center items-center m-6">
        <img
          className="cursor-pointer m-6 h-[20px]"
          src="assets/leftCarat.png"
          onClick={() =>
            selectedSize > 4
              ? setSelectedSize(selectedSize - 1)
              : setSelectedSize(6)
          }
        ></img>
        <BoardSizeThumbnail
          boardSize={4}
          squareSize="p-[20px]"
          visible={selectedSize == 4}
        />
        <BoardSizeThumbnail
          boardSize={5}
          squareSize="p-[16px]"
          visible={selectedSize == 5}
        />
        <BoardSizeThumbnail
          boardSize={6}
          squareSize="p-[13.3px]"
          visible={selectedSize == 6}
        />
        <img
          className="cursor-pointer m-6 h-[20px]"
          src="assets/rightCarat.png"
          onClick={() =>
            selectedSize < 6
              ? setSelectedSize(selectedSize + 1)
              : setSelectedSize(4)
          }
        ></img>
      </div>

      <h1 className="text-5xl font-mencken text-center">{`${selectedSize}x${selectedSize}`}</h1>
      <h2 className="m-2 text-xl font-hoss font-light text-center">
        {getSubtitleForSize(selectedSize)}
      </h2>
      <StandardButton
        className="bg-teal px-4 m-4 text-xl text-cream"
        text="NEXT"
        onClick={() => setBoardStage(boardStage + 1)}
      />
    </div>
  );
};
