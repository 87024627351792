import { useState } from "react";
import { MenuButton } from "../components/buttons/MenuButton";
import { SecondaryLogo } from "../logos/SecondaryLogo";
import { ChooseSquaresPage } from "./ChooseSquaresPage";
import { MenuPage } from "../menu/MenuPage";
import { ChooseBoardSizePage } from "./ChooseBoardSizePage";
import { ChooseBoardThemePage } from "./ChooseBoardThemePage";
import { IBingoSquare } from "../interfaces/IBingoSquare";
import { FinishBoardCreationPage } from "./FinishBoardCreationPage";

export enum CreateBoardStage {
  CHOOSE_SIZE,
  CHOOSE_THEME,
  CHOOSE_SQUARES,
  CHOOSE_NAME,
  FINALIZE_BOARD,
}

export const boardStages = [
  CreateBoardStage.CHOOSE_SIZE,
  CreateBoardStage.CHOOSE_THEME,
  CreateBoardStage.CHOOSE_SQUARES,
];

export enum BoardTheme {
  CLASSIC = "classic",
  RED_FLAGS = "red flags",
  GREEN_FLAGS = "green flags",
}

export const getIndexForBoardTheme = (theme: BoardTheme): number => {
  return Object.values(BoardTheme).indexOf(theme);
};

export const getDescriptionForBoardTheme = (theme: BoardTheme): string => {
  switch (theme) {
    case BoardTheme.CLASSIC:
      return "chaotic neutral";
    case BoardTheme.RED_FLAGS:
      return "ok masochist";
    case BoardTheme.GREEN_FLAGS:
      return "good for you!";
  }
};

export const CreateBoardPage = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [selectedSize, setSelectedSize] = useState<number>(5);
  const [selectedTheme, setSelectedTheme] = useState<BoardTheme>(
    BoardTheme.CLASSIC
  );
  const [boardStage, setBoardStage] = useState<CreateBoardStage>(
    CreateBoardStage.CHOOSE_SIZE
  );
  const [board, setBoard] = useState<IBingoSquare[][]>();
  const [title, setTitle] = useState<string>("");
  const [currentBoardTheme, setCurrentBoardTheme] = useState<BoardTheme>(
    BoardTheme.CLASSIC
  );

  const getTitleForStage = (stage: CreateBoardStage): string | undefined => {
    switch (stage) {
      case CreateBoardStage.CHOOSE_SIZE:
        return "HOW BIG DO YOU WANT IT?";
      case CreateBoardStage.CHOOSE_THEME:
        return "WHAT'S THE VIBE?";
      case CreateBoardStage.CHOOSE_NAME:
        return "WHAT SHOULD WE CALL IT?";
      case CreateBoardStage.CHOOSE_SQUARES:
        return undefined;
      case CreateBoardStage.FINALIZE_BOARD:
        return undefined;
    }
  };

  const getShortTitleForStage = (stage: CreateBoardStage): string => {
    switch (stage) {
      case CreateBoardStage.CHOOSE_SIZE:
        return "SIZE";
      case CreateBoardStage.CHOOSE_THEME:
        return "THEME";
      case CreateBoardStage.CHOOSE_NAME:
        return "NAME";
      case CreateBoardStage.CHOOSE_SQUARES:
        return "REVIEW";
      case CreateBoardStage.FINALIZE_BOARD:
        return "REVIEW";
    }
  };

  const getComponentForStage = (stage: CreateBoardStage): React.ReactNode => {
    switch (stage) {
      case CreateBoardStage.CHOOSE_SIZE:
        return (
          <ChooseBoardSizePage
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
            boardStage={boardStage}
            setBoardStage={setBoardStage}
          />
        );
      case CreateBoardStage.CHOOSE_THEME:
        return (
          <ChooseBoardThemePage
            selectedTheme={selectedTheme}
            setSelectedTheme={setSelectedTheme}
            boardStage={boardStage}
            setBoardStage={setBoardStage}
          />
        );
      case CreateBoardStage.CHOOSE_SQUARES:
        return (
          <ChooseSquaresPage
            board={board}
            setBoard={setBoard}
            boardSize={selectedSize}
            selectedTheme={selectedTheme}
            boardStage={boardStage}
            setBoardStage={setBoardStage}
            currentBoardTheme={currentBoardTheme}
            setCurrentBoardTheme={setCurrentBoardTheme}
          />
        );
      case CreateBoardStage.FINALIZE_BOARD:
        return <FinishBoardCreationPage title={title} board={board} />;
    }
  };

  const header = getTitleForStage(boardStage);

  return menuOpen ? (
    <MenuPage setMenuOpen={setMenuOpen} />
  ) : (
    <div className="">
      <SecondaryLogo />
      <MenuButton onClick={() => setMenuOpen(true)} />
      <div
        className={`flex flex-col items-center relative top-[100px] font-hoss font-light`}
      >
        <div className="flex flex-row justify-center items-center mt-[20px] mb-[40px]">
          {boardStages.map((stage) => {
            return (
              <div
                className="flex flex-row justify-center items-center"
                key={`stageMarker_${stage}`}
              >
                <div className="relative">
                  <div
                    onClick={() => setBoardStage(stage)}
                    className={`rounded-[50%] cursor-pointer w-6 h-6 relative ${
                      boardStage >= stage ? "bg-orange" : "bg-transparent"
                    } border border-chocolate`}
                  >
                    <p className="text-sm absolute -bottom-8 right-[50%] translate-x-[50%]">
                      {getShortTitleForStage(stage as CreateBoardStage)}
                    </p>
                  </div>
                </div>
                {stage == boardStages.length - 1 ? null : (
                  <div className="h-[2px] w-10 bg-chocolate"></div>
                )}
              </div>
            );
          })}
        </div>
        {header && <h2 className="text-3xl text-center mt-6">{header}</h2>}
        {getComponentForStage(boardStage)}
      </div>
    </div>
  );
};
