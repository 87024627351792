import { Navigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const WithPrivateRoutePassParams = ({ children }) => {
  const authInfo = useAuth();
  const params = useParams();
  const stringifiedParams = Object.values(params).join("/");

  if (authInfo.currentUserInfo) {
    return children;
  }

  return <Navigate to={`/login/${stringifiedParams}`} />;
};

export default WithPrivateRoutePassParams;
