import { createContext, useContext, useEffect, useState } from "react";
import * as firebaseApp from "./firebase";
import * as firebaseAuth from "firebase/auth";
import { getAuth, RecaptchaVerifier, signOut } from "firebase/auth";
import { IUserShortInfo } from "../interfaces/IUserShortInfo";

interface IAuthContext {
  currentFirebaseUser: firebaseAuth.User | null;
  currentUserInfo: IUserShortInfo | null;
  logout: () => void;
  setCurrentUserInfo: React.Dispatch<
    React.SetStateAction<IUserShortInfo | null>
  >;
}

const AuthContext: React.Context<IAuthContext> = createContext(
  {} as IAuthContext
);

export function useAuth() {
  return useContext(AuthContext);
}

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [currentFirebaseUser, setCurrentFirebaseUser] =
    useState<firebaseAuth.User | null>(null);
  const [currentUserInfo, setCurrentUserInfo] = useState<IUserShortInfo | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const _firebase = firebaseApp;
  const auth = getAuth();

  async function logout() {
    await signOut(auth);
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fetch(`/user/${user.uid}`)
          .then((res) => res.json())
          .then((data) => {
            if (data) {
              setCurrentUserInfo(data);
              setCurrentFirebaseUser(user);
            } else {
              setCurrentUserInfo(null);
              setCurrentFirebaseUser(user);
            }
            setLoading(false);
          });
      } else {
        setCurrentFirebaseUser(null);
        setCurrentUserInfo(null);
        setLoading(false);
      }
    });
  }, []);


  const value = {
    logout,
    currentFirebaseUser,
    currentUserInfo,
    setCurrentUserInfo,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
