import { useNavigate } from "react-router-dom";
import { useScreenDimensions } from "../hooks/useScreenDimensions";
import { useAuth } from "../contexts/AuthContext";

export const SecondaryLogo = () => {
  const screenDims = useScreenDimensions();
  const authInfo = useAuth();
  const navigate = useNavigate();
  const src = "/assets/DB_Logo_Stacked_PinkO_Slant.png";
  return (
    <img
      className={`absolute left-0 top-0 m-2 p-1 cursor-pointer z-10 h-[80px]`}
      src={src}
      onClick={() => navigate("/")}
    />
  );
};
